(function (angular, _) {
    'use strict';

    angular.module('McoCollectionPortal.memorix')
        .factory('MmxWizardStep', MmxWizardStep);

    /* @ngInject */
    function MmxWizardStep($state, $q, EntryList) {
        var mmxWizardStep = {
            $new: createWizardStep
        };

        var WizardStep = function (definition) {
            var self = this;
            
            self.initialized = $q.defer();
            
            //Unique ID
            self.id = new Date().getTime().toString() + '-' + Math.random().toString().substr(4);

            //Wizard related properties
            self.order = 0;
            self.target = '';
            self.active = false;
            self.name = null;
            self.help = false; //Path to help file.
            self.helpLabel = '';
            self.helpTitle = '';
            self.helpList = [];
            self.skip = false;
            self.lists = null;

            self.label = '';
            self.elements = [];
            self.options = {};
            self.data = {};

            angular.forEach(
                definition,
                function (value, property) {
                    self[property] = value;
                }
            );

            this.setElements(self.elements);
            //Fix repeatable entities.
        };


        WizardStep.prototype = {
            'constructor': WizardStep,
            'getOption': getOption,
            'setActive': setWizardStepActive,
            'getActive': getWizardStepActive,
            'setElements': setWizardStepElements,
            'getTarget': getWizardStepTarget,
            'getHelp': getWizardStepHelp,
            'getHelpLabel': getWizardStepHelpLabel,
            'getHelpTitle': getWizardStepHelpTitle,
            'getHelpList': getWizardStepHelpList
        };

        /**
         * @param {Boolean} state
         **/
        function setWizardStepActive(state) {
            /*jshint validthis:true */
            this.active = state;
            return this;
        }

        /**
         * @return {Boolean}
         **/
        function getWizardStepActive() {
            /*jshint validthis:true */
            return this.active;
        }

        /**
         * @return {String}
         **/
        function getWizardStepTarget() {
            /*jshint validthis:true */
            return this.target;
        }

        /**
         * Get an option
         * @return {Mixed}
         **/
        function getOption (key) {
            /*jshint validthis:true */
            return this.options && typeof this.options[key] !== 'undefined' ?
                this.options[key] : 'Option not found';
        }

        /**
         * @return {Boolean|String}
         **/
        function getWizardStepHelp() {
            /*jshint validthis:true */
            return this.help;
        }

        /**
         * @return {Boolean|String}
         **/
        function getWizardStepHelpLabel() {
            /*jshint validthis:true */
            return this.helpLabel;
        }

        function getWizardStepHelpTitle() {
            /*jshint validthis:true */
            return this.helpTitle;
        }

        function getWizardStepHelpList() {
            /*jshint validthis:true */
            return this.helpList;
        }


        function setWizardStepElements(els) {
            /*jshint validthis:true */
            var self = this;
            this.elements = _.filter(
                els,
                function (el) {
                    return typeof el.skip === 'undefined' || !el.skip;
                }
            );

            if (this.lists === null) {
                //Seed control lists.
                var controlLists = [];
                angular.forEach(
                    this.elements,
                    function (fieldset) {
                        angular.forEach(
                            fieldset.elements,
                            function (element) {
                                if (element.template === 'controllist') {
                                    controlLists.push(element.list);
                                }

                                if (element.template.indexOf('entities') !== -1 &&
                                    typeof self.data[element.name] === 'undefined') {
                                    //Repeatable entity initialize.
                                    self.data[element.name] = [];
                                    if (typeof element.list !== 'undefined') {
                                        controlLists.push(element.list);
                                    }
                                }

                                if (typeof self.data[element.name] === 'undefined' &&
                                    element.options && element.options['defaultValue']) {
                                    self.data[element.name] = element.options['defaultValue'];
                                }
                            }
                        );
                        self.initialized.resolve(self);
                    }
                );
                
                if (controlLists.length > 0) {
                    var entryListModel = new EntryList();
                    entryListModel.rawRequest(
                        'entrylist',
                        'POST',
                        {},
                        {
                            'lists': controlLists
                        }
                    ).then(
                        function (response) {
                            if (typeof response.data.lists !== 'undefined') {
                                self.lists = response.data.lists;
                            }
                        }
                    );
                }
            }
        }

        /**
         * @param {Array} descriptor
         **/
        function createWizardStep(definition) {
            return new WizardStep(definition);
        }

        return mmxWizardStep;
    }
})(window.angular, window._);
