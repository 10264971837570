(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.core')
        .factory('gridBuilder', GridProvider);

    /* @ngInject */
    function GridProvider(ngTableParams, ngDialog, messageService, session) {
        var gridBuilder = {
            $new: createGrid
        };

        var defaultGridParameters = {
            page: 1,
            count: 10,
            sorting: {},
            filter: {}
        };

        /**
         * Create an NG table grid.
         * @param {Object} params for ngTable
         * @param {Function} constructor
         * @param {Object} cols descriptor
         **/
        var Grid = function (gridParameters, ResourceModel, cols) {
            var self = this;
            self.model = new ResourceModel();
            self.columns = cols;
            self.loading = false;
            self.removeRecord = removeRow;
            self.toggleRecord = toggleRow;
            self.openDialog = formRow;

            /*jshint -W055 */
            self.ngTable = new ngTableParams(
                gridParameters,
                {
                    counts: [],
                    getData: getGridData
                }
            );

            /**
             * Remove a record.
             * @param {String} record id
             * @param {Object} event object
             **/
            function removeRow(rowId, $event) {
                /*jshint validthis:true */
                this.preventEventPropagation($event);
                self.model.$remove(
                    {'id': rowId},
                    function (response)  {
                        self.ngTable.reload();
                    }
                );
            }

            /**
             * Fetch NG table data.
             * @param {Object} deferred object.
             * @param {Object} params.
             **/
            function getGridData($deferred, params) {
                self.model.$ngTableFetch($deferred, params, self);
            }

            /**
             * Toggle a boolean record property
             * @param {String} Record id.
             * @param {String} url to call.
             * @param {Object} event object
             **/
            function toggleRow(rowId, url, $event) {
                /*jshint validthis:true */
                this.preventEventPropagation($event);
                self.model.rawRequest(
                    url,
                    'PUT',
                    {},
                    {'id': rowId}
                ).then(
                    function (response)  {
                        self.ngTable.reload();
                    }
                );
            }

            /**
             * Open a form template for the specific record.
             * @param {String} template
             **/
            function formRow(template, record) {
                if (typeof record === 'undefined') {
                    record = {};
                }

                session.$put(
                    'dialogReference',
                    ngDialog.open(
                        {
                            className: 'ngdialog-theme-plain',
                            template: template,
                            data: JSON.stringify(record)
                        }
                    )
                );
            }
        };

        Grid.prototype = {
            'constructor': Grid,
            'preventEventPropagation': function($event) {
                if ($event.stopPropagation) {
                    $event.stopPropagation();
                }
                if ($event.preventDefault) {
                    $event.preventDefault();
                }
                $event.cancelBubble = true;
                $event.returnValue = false;
            }
        };

        return gridBuilder;

        /**
         * Create an NG table grid.
         * @param {Object} params
         * @param {Function} cb
         **/
        function createGrid(params, cb, cols) {
            var gridParams = angular.copy(defaultGridParameters);
            if (typeof params !== 'undefined') {
                angular.extend(gridParams, params);
            }
            return new Grid(gridParams, cb, cols);
        }
    }
})(window.angular);
