(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.memorix').service('TmpFile', TmpFile);

    /* @ngInject */
    function TmpFile (resourceBuilder) {
        return resourceBuilder.$new(
            'files',
            {id: '@_id'},
            {},
            {}
        );
    }
})(window.angular);
