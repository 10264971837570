(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.correction').service('Correction', Correction);

    /* @ngInject */
    function Correction ($state, $window, ngDialog, session, resourceBuilder, messageService, User, Institution, Monument) {
        var persist = this;

        return resourceBuilder.$new(
            'correction',
            {id: '@_id'},
            {},
            {
                'setStatus': statusUpdate,
                'loadReferences': loadReferences,
                'removeRecord': removeRecord
            }
        );

        function removeRecord() {
          /*jshint validthis:true */
          var confirm = $window.confirm('Weet u zeker dat u deze correctie wilt verwijderen?');
          if(confirm === true) {
            if (session.$isset('dialogReference')) {
              ngDialog.close(session.$get('dialogReference'));
            }

            return this.rawRequest(
                'correction/' + this._id,
                'DELETE',
                {},
                {}
            ).then(
                function (response) {
                    $state.transitionTo('mco.requests.corrections', {}, {reload:true, notify: true, inherit: true});
                }
            );
          }
        }

        function statusUpdate(status) {
            /*jshint validthis:true */
            if (session.$isset('dialogReference')) {
                ngDialog.close(session.$get('dialogReference'));
            }

            return this.rawRequest(
                'correction/status',
                'PUT',
                {},
                {
                    'id': this._id,
                    'status': status
                }
            ).then(
                function (response) {
                    $state.transitionTo('mco.requests.corrections', {}, {reload:true, notify: true, inherit: true});
                }
            );
        }

        function loadReferences() {
            /*jshint validthis:true */
            var self =this;
            if(typeof this.userId === 'object') {
                this.userId = this.userId.$id;
            }
            User.get(
                {id: this.userId},
                function (response) {
                    self.user = response.data.data;
                }
            );
        }
    }
})(window.angular);
