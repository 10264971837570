(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.user').controller('User.GridCtrl', GridController);

    /* @ngInject */
    function GridController ($state, gettext, gridBuilder, User, messageService) {
        var vm = this;

        vm.dataGrid = gridBuilder.$new(
            {
                sorting: {
                    'displayName': 'asc'
                }
            },
            User,
            {
								lastvisitDate: gettext('Inlogdatum'),
                displayName: gettext('Name'),
                email: gettext('E-mail'),
                role: gettext('Role'),
                institutionCount: gettext('Kerken'),
                actions: gettext('Actions'),
                disable: gettext('Disable'),
                enable: gettext('Enable')
            }
        );

        vm.profile = loadProfile;

        function loadProfile(userId) {
            $state.transitionTo(
                'mco.users.profile',
                {id: userId}
            );
        }
    }
})(window.angular);
