(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.registration').controller(
        'Registration.FormCtrl',
        FormController
    );

    /* @ngInject */
    function FormController ($state, $scope, $window, ngDialog, session, gettext, urlHelper, Registration, User,
                             formBuilder, messageService, AutocompleteHelper) {
        var vm = this;

        vm.record = {};
        vm.reject = rejectRegistration;
        vm.removeRecord = removeRecord;
        vm.assignUser = assignUser;
        vm.rejectDone = false;

        vm.institutionsDisabled = false;
        vm.userInstitutionsDisabled = false;
        vm.denominationInstitutionsDisabled = false;
        vm.organizationInstitutions = false;

        vm.roles = {
            'Administrator': gettext('Administrator'),
            'Expert': gettext('Expert'),
            'Read only': gettext('Read only'),
            'Read only all': gettext('Read only all'),
            'Regular': gettext('Regular')
        };

        vm.$form = formBuilder.$new(
            User,
            [
                'firstName',
                'lastName',
                'institutions',
                'email',
                'reviewer',
                'role',
                'reason',
                'status'
            ],
            { 'general': messageService.$get('reg_valid_error') },
            {
                'prePersist': function (formData) {
                    formData.displayName = vm.record.displayName;
                    formData.registrationId = vm.record._id;
                    vm.record._id = null;
                    return formData;
                }
            }
        );

        vm.institutionAutocomplete = AutocompleteHelper.$new(vm);
        vm.institutionAutocomplete['label'] = messageService.$get('autocomplete_inst_label', true);
        vm.institutionAutocomplete['url'] = urlHelper.getAutocompleteUrl('reginstitutions');

        vm.userAutocomplete = AutocompleteHelper.$new(vm);
        vm.userAutocomplete['label'] = messageService.$get('autocomplete_user_label', true);
        vm.userAutocomplete['url'] = urlHelper.getAutocompleteUrl('userinstitutions');

        vm.adminAutocomplete = AutocompleteHelper.$new(vm);
        vm.adminAutocomplete['url'] = urlHelper.getAutocompleteUrl('admins');

        vm.denominationAutocomplete = AutocompleteHelper.$new(vm);
        vm.denominationAutocomplete['label'] = messageService.$get('autocomplete_denomination_label', true);
        vm.denominationAutocomplete['url'] = urlHelper.getAutocompleteUrl('denominations');
        vm.denominationAutocomplete['chain'] = urlHelper.getAutocompleteUrl('denominationinstitutions');

        vm.organizationAutocomplete = AutocompleteHelper.$new(vm);
        vm.organizationAutocomplete['label'] = messageService.$get('autocomplete_organization_label', true);
        vm.organizationAutocomplete['url'] = urlHelper.getAutocompleteUrl('umbrellaorganizations');
        vm.organizationAutocomplete['chain'] = urlHelper.getAutocompleteUrl('umbrellaorganizationinstitutions');

        activate();

        function activate() {
            if ($scope.$parent.ngDialogData) {
                vm.$form.setData($scope.$parent.ngDialogData);
                vm.record = $scope.$parent.ngDialogData;
            }
        }

        function assignUser() {
            vm.record.reviewer = vm.$form.fields.reviewer;
            var user = new User(vm.record);
            if (session.$isset('dialogReference')) {
                ngDialog.close(session.$get('dialogReference'));
            }

            return user.rawRequest(
                'registration/assign',
                'PUT',
                {},
                {
                    'id': user._id,
                    'reviewer': user.reviewer
                }
            ).then(
                function (response) {
                    if (response.data.msg !== 'reg_assign_fail') {
                        $state.transitionTo('mco.requests', {}, {reload:true, notify: true, inherit: true});
                    }
                }
            );
        }        

        function removeRecord() {
            /*jshint validthis:true */
            var confirm = $window.confirm('Weet u zeker dat u deze accountaanvraag wilt verwijderen?');
            if(confirm === true) {
              var user = new User(vm.record);
              if (session.$isset('dialogReference')) {
                ngDialog.close(session.$get('dialogReference'));
              }
  
              return user.rawRequest(
                  'registration/' + user._id,
                  'DELETE',
                  {},
                  {}
              ).then(
                  function (response) {
                    if (response.data.msg !== 'reg_delete_fail') {
                      $state.transitionTo('mco.requests', {}, {reload:true, notify: true, inherit: true});
                    }
                  }
              );
            }
        }

        function rejectRegistration() {
            var user = new User(vm.record);
            user.rawRequest(
                'registration/reject',
                'PUT',
                {},
                {
                    'id': user._id,
                    'institutions': user.institutions,
                    'status': status
                }
            ).then(
                function (response) {
                    if (response.data.msg !== 'reg_reject_fail') {
                        vm.rejectDone = true;
                    }
                }
            );
        }
    }
})(window.angular);
