(function (angular) {
    'use strict';

    // Setup application routing
    angular.module(
        'McoCollectionPortal',
        [
            'ui.router',
            'ngResource',
            'ngDialog',
            'ngTable',
            'ngTouch',
            'mm.foundation',
            'gettext',
            'ab-base64',
            'angucomplete-alt',
            'textAngular',
            //'ngQuickDate',

            //Do not touch this next line, fucker....(I keep removing it...)
            'McoCollectionPortal.partials',
            //----^^-------
            'McoCollectionPortal.announcement',
            'McoCollectionPortal.core',
            'McoCollectionPortal.correction',
            'McoCollectionPortal.dashboard',
            'McoCollectionPortal.memorix',
            'McoCollectionPortal.registration',
            'McoCollectionPortal.request',
            'McoCollectionPortal.user'
        ]
    )
        .config( routeConfig )
        .config( authConfig )
        .config( errorConfig);

    /* @ngInject */
    function routeConfig($locationProvider, $urlRouterProvider, $stateProvider) {
        $urlRouterProvider.when('', '/');
        $urlRouterProvider.otherwise('/not-found');

        $stateProvider
        // Root is our main state all the other ones are its children and are rendered inside layout.html
            .state('mco', {
                url: '',
                views: {
                    '': {
                        templateUrl: 'layout.html'
                    },
                    'appHeader@mco': {
                        templateUrl: 'core/views/partials/header.html',
                        controller: 'Core.HeaderCtrl',
                        controllerAs: 'header'
                    }
                }
            });

        // If none of the states are matched go to 404 page
        $locationProvider.html5Mode(true);
    }

    /* @ngInject */
    function authConfig($httpProvider) {
        $httpProvider.interceptors.push('jwt');
    }

    /* @ngInject */
    function errorConfig($provide, $httpProvider) {
        //Exception handler
        ////////////////////////////////
        // $provide.decorator(        //
        //     '$exceptionHandler',   //
        //     customExceptionHandler //
        // );                         //
        ////////////////////////////////
    }

    /* @ngInject */
    function customExceptionHandler($delegate, gettext, gettextCatalog, messageService) {
        return function (exception, cause) {
            var errorKey = typeof exception.message !== 'undefined' ? exception.message : '';
            messageService.$raise(errorKey, errorKey);
            $delegate(exception, cause);
        };
    }

    /* @ngInject */
    function accessControl($rootScope, $state, User, gettextCatalog) {
        gettextCatalog.setCurrentLanguage('nl');
        gettextCatalog.debug = false;

        $rootScope.$on(
            '$Statechangesuccess',
            function (event, nextState, nextParams, fromState, fromParams) {
                var user = null;
                var isAllowed = false;

                if (typeof nextState.access !== 'undefined') {
                    //The controller has specified ACL, we have to be
                    //logged in and the user safely in the session.
                    //This auth doesn't need security but usability.
                    //We enforce it server side.
                    user = new User();
                    user = user.identity();

                    //Does the user have access?
                    if (user.isLogged()) {
                        angular.forEach(
                            nextState.access,
                            function(expected, restriction) {
                                isAllowed = typeof user[restriction] === 'function' &&
                                    (user[restriction]() === expected);
                            }
                        );
                    }

                    if (!isAllowed) {
                        return $state.go(
                            'mco.home'
                        );
                    }
                }
            }
        );
    }

    ////////////////////////////////////////////////////
    // /* @ngInject */                                //
    // function richTextProvider($provide) {          //
    //     $provide.decorator('tabOptions', [         //
    //         '$delegate',                           //
    //         function (tabOptions) {                //
    //             tabOptions.toolbar = ['h1', 'h2']; //
    //             return tabOptions;                 //
    //         }                                      //
    //     ]);                                        //
    // }                                              //
    ////////////////////////////////////////////////////
    angular.module('McoCollectionPortal')
        .config(
            function( /* ngQuickDateDefaultsProvider, */ gettext) {
                //////////////////////////////////////////////////////////////////////
                // //This plugin is a shitty buggy piece of poop, but I             //
                // //don't want to have to do it myself and didn't find             //
                // //better. Formatting is set like this so it conforms             //
                // //to the mmx formatting.                                         //
                // ngQuickDateDefaultsProvider.set(                                 //
                //     {                                                            //
                //         'dateFormat': 'dd/MM/yyyy',                              //
                //         'disableTimepicker': true,                               //
                //         'disableClearButton': true,                              //
                //         'placeholder': gettext('Enter Date'),                    //
                //         'parseDateFunction':function(str) {                      //
                //             var seconds = null;                                  //
                //             var date = null;                                     //
                //             if (typeof str.split === 'function') {               //
                //                 date = str.split('/');                           //
                //                 if (date.length === 3) {                         //
                //                     str = [date[1], date[0], date[2]].join('/'); //
                //                 }                                                //
                //                                                                  //
                //             }                                                    //
                //             seconds = Date.parse(str);                           //
                //             return isNaN(seconds) ? null : new Date(seconds);    //
                //         }                                                        //
                //     }                                                            //
                // );                                                               //
                //////////////////////////////////////////////////////////////////////
            }
        );
    angular.module('McoCollectionPortal')
        .config(['$provide', function($provide){
        // this demonstrates how to register a new tool and add it to
        // the default toolbar
        $provide.decorator('taOptions', ['$delegate', function(taOptions){
            // $delegate is the taOptions we are decorating
            // here we override the default toolbars and classes
            // specified in taOptions.
            taOptions.toolbar = [
                ['h1', 'h2', 'h3', 'insertLink', 'bold', 'italics', 'underline']
            ];
            taOptions.classes = {
                focussed: 'focussed',
                toolbar: 'button-bar',
                toolbarGroup: 'button-group',
                toolbarButton: 'button small',
                toolbarButtonActive: 'active',
                disabled: 'disabled',
                textEditor: 'form-control',
                htmlEditor: 'form-control'
            };
            return taOptions; // whatever you return will be the
            // taOptions
        }]);

            $provide.decorator('taTools', ['$delegate', function(taTools){
                taTools.insertLink.buttontext = 'HREF';
                taTools.insertLink.iconclass = '';
                taTools.bold.buttontext = 'B';
                taTools.bold.iconclass = '';
                taTools.italics.buttontext = 'I';
                taTools.italics.iconclass = '';
                taTools.underline.buttontext = 'U';
                taTools.underline.iconclass = '';
                return taTools;
            }]);
        }]);

    // Register angular application & dependencies
    angular.module('McoCollectionPortal').run( accessControl );

})(window.angular);
