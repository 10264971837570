(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.memorix').service('Institution', Institution);


    /* @ngInject */
    function Institution (resourceBuilder, urlHelper) {
        return resourceBuilder.$new(
            'institution',
            {id: '@_id'},
            {},
            {
                'getThumbnail' : function () {
                    /*jshint validthis:true */
                    var imageUrl = 'img/placeholder-photo.jpg';
                    if (this.media && this.media.length > 0) {
                        imageUrl = urlHelper.getThumbnailUrl(this.media[0].uuid);
                    }
                    return imageUrl;
                }
            }
        );
    }
})(window.angular);
