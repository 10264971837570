(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.registration').service('Registration', Registration);

    /* @ngInject */
    function Registration (resourceBuilder) {
        var persist = this;

        return resourceBuilder.$new(
            'registration',
            {id: '@_id'},
            {},
            {}
        );
    }
})(window.angular);
