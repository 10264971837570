(function (angular, _) {
  'use strict';

  angular.module('McoCollectionPortal.core').filter('orderObjectBy', orderObjectBy);

  /* @ngInject */
  function orderObjectBy () {
    return function(items, field, reverse) {
      var filtered = [];
      angular.forEach(items, function(item) {
        filtered.push(item);
      });
      filtered.sort(function (a, b) {
        return (a[field] > b[field] ? 1 : -1);
      });
      if(reverse) filtered.reverse();
      return filtered;
    };
  }
})(window.angular, window._);
