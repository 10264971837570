(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.request').controller('Request.FormCtrl', FormController);


    /* @ngInject */
    function FormController ($scope, gettext, ngDialog, AutocompleteHelper,
                             formBuilder, Request, messageService, urlHelper) {
        var vm = this;

        vm.record = {};

        vm.$form = formBuilder.$new(
            Request,
            [
                'subject',
                'body',
                'institutions'
            ],
            {
                'general': messageService.$get('request_valid_error')
            }
        );

        vm.institutionAutocomplete = AutocompleteHelper.$new(vm);
        vm.institutionAutocomplete['label'] = messageService.$get('autocomplete_inst_label');
        vm.institutionAutocomplete['url'] = urlHelper.getAutocompleteUrl('reginstitutions');

        activate();

        function activate() {
            if ($scope.$parent.ngDialogData) {
                vm.$form.setData($scope.$parent.ngDialogData);
                vm.record = new Request($scope.$parent.ngDialogData);
            }
        }
    }
})(window.angular);
