(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.core')
        .factory('formBuilder', FormProvider);

    /* @ngInject */
    function FormProvider($state, ngDialog, session) {
        var formBuilder = {
            $new: createForm
        };

        /**
         * Create a simple form manager.
         * @param {Function} constructor
         * @param {Object} fieldsDescriptor
         * @param {Object} params for messages.
         * @param {Object} params for callbacks.
         **/
        var Form = function (ResourceModel, fields, msgs, cbs) {
            var self = this;
            self.modelConstructor = ResourceModel;
            self.messages = msgs;
            self.callbacks = cbs;
            self.fields = {};
            angular.forEach(
                fields,
                function(field) {
                    self.fields[field] = null;
                }
            );
            self.valid = true;

            self.$persist = persistForm;
        };

        Form.prototype = {
            'constructor': Form,
            'isValid': function () {
                return this.valid;
            },
            'getMessage': function (key) {
                var msg = '';
                if (typeof this.messages[key] !== 'undefined') {
                    msg = this.messages[key];
                }
                return msg;
            },
            'getData': function(filter) {
                var self = this;
                var fields = this.fields;
                if (typeof filter !== 'undefined') {
                    fields = {};
                    angular.forEach(
                        filter,
                        function (value) {
                            fields[value] = self.fields[value];
                        }
                    );
                }
                return fields;
            },
            'setData': function(source) {
                var self = this;

                angular.forEach(
                    source,
                    function (value, key) {
                        if (typeof self.fields[key] !== 'undefined') {
                            self.fields[key] = value;
                        }
                    }
                );
            }
        };

        return formBuilder;

        /**
         * Create a simple form manager.
         * @param {Function} constructor
         * @param {Object} fieldsDescriptor
         * @param {Object} params for messages.
         * @param {Object} params for callbacks.
         **/
        function createForm(ResourceModel, fields, messages, callbacks) {
            if (typeof messages !== 'object') {
                messages = {};
            }
            if (typeof callbacks !== 'object') {
                callbacks = {};
            }
            return new Form(ResourceModel, fields, messages, callbacks);
        }

        function persistForm(isValid, executionContext, targetState)
        {
            /*jshint validthis:true */
            var self = this;
            var formData = {};

            if (isValid) {
                this.valid = true;
                if (session.$isset('dialogReference')) {
                    ngDialog.close(session.$get('dialogReference'));
                }

                formData = this.getData();
                if (typeof this.callbacks['prePersist'] === 'function') {
                    formData = this.callbacks['prePersist'](formData);
                }

                /*jshint -W055 */
                var record = new this.modelConstructor(formData);

                if (typeof executionContext.record._id !== 'undefined' && executionContext.record._id) {
                    record._id = executionContext.record._id;
                }

                record.$save(
                    function (response) {
                        if (typeof self.callbacks['postPersist'] === 'function') {
                            self.callbacks['postPersist'](response);
                        } else if(typeof response.data.msg !== 'undefined' && targetState) {
                            $state.transitionTo(targetState, {}, {reload:true, notify: true, inherit: true});
                        }
                    }
                );
            } else {
                this.valid = false;
            }
        }
    }
})(window.angular);
