(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.announcement').controller('Announcement.OverviewCtrl', OverviewController);


    /* @ngInject */
    function OverviewController ($state, $sce, $timeout, Announcement) {
        var vm = this;
        vm.activeId = null;
        vm.records = [];
        vm.setActive = activeRecord;
        vm.cycleRecords = cycleRecords;
        vm.iteration = null;
        
        activate();

        function activate() {
            Announcement.query(
                {
                    'sort': {
                        'created': 'desc'
                    },
                    'pagination': {
                        'skip': 0,
                        'limit': 3
                    },
                    'filter': {
                        'published': true
                    }
                },
                function(response) {
                    vm.records = response.data.data;
                    angular.forEach(
                        vm.records,
                        function (record) {
                            record.body = $sce.trustAsHtml(record.body);
                        }
                    );
                    if (typeof vm.records[0] !== 'undefined') {
                        vm.setActive(vm.records[0], 0);
                        vm.iteration = vm.cycleRecords();
                    }
                }
            );
        }

        function activeRecord(record, index, clear) {
            vm.activeId = record._id;
            vm.activeIndex = index;
            if (clear === true) {
                $timeout.cancel(vm.iteration);
            }
        }

        function cycleRecords() {
            return $timeout(
                function () {
                    var recordIndex = 0;
                    if ((vm.activeIndex + 1) < vm.records.length) {
                        recordIndex = vm.activeIndex + 1;
                    }
                    vm.setActive(vm.records[recordIndex], recordIndex);
                    vm.iteration = vm.cycleRecords();
                },
                5000
            );
        }
    }
})(window.angular);
