(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.user').controller('Registration.GridCtrl', GridController);

    /* @ngInject */
    function GridController ($state, gettext, gridBuilder, Registration) {
        var vm = this;
        vm.dataGrid = gridBuilder.$new(
            {
                sorting: {
                    'created': 'desc'
                },
                filter: {
                  'status': 'new'
                }
            },
            Registration,
            {
                status: gettext('Status'),
                created: gettext('Registered'),
                name: gettext('Name'),
                email: gettext('E-mail'),
                reviewer: gettext('In behandeling bij'),
                access: gettext('Additional access'),
                institution: gettext('Institution'),
                actions: gettext('Actions')
            }
        );

        vm.registration = null;
        vm.profile = loadProfile;

        function loadProfile(registrationId) {
            $state.transitionTo(
                'mco.users.registrations.profile',
                {id: registrationId}
            );
        }
    }
})(window.angular);
