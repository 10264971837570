(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.core', [])
        .config( routeConfig );


    /* @ngInject */
    function routeConfig($stateProvider) {
        $stateProvider
            .state('not-found', {
                url: '/not-found',
                templateUrl: 'core/views/partials/not-found.html'
            })
            .state('mco.home', {
                url: '/',
                views: {
                    '': {
                        templateUrl: 'core/views/index.html',
                        controller: 'Core.IndexCtrl'
                    },
                    'login@mco.home': {
                        templateUrl: 'user/views/partials/login.html',
                        controller: 'User.LoginCtrl',
                        controllerAs: 'login'
                    },
                    'registration@mco.home': {
                        templateUrl: 'registration/views/partials/index.html',
                        controller: 'Registration.RegisterCtrl',
                        controllerAs: 'register'
                    }
                }
            })
            .state('mco.login', {
                url: '/login/:token/:status',
                controller: 'User.LoginCtrl'
            })
            .state('mco.logout', {
                url: '/logout',
                controller: 'User.LogoutCtrl'
            });
    }

})(window.angular);
