(function (angular, $) {
    'use strict';

    angular.module('McoCollectionPortal.core')
        .factory('spinner', SpinnerProvider);

    /* @ngInject */
    function SpinnerProvider() {
        var defaultOptions = {
            lines: 15,
            length: 20,
            width: 5,
            radius: 15,
            trail: 25,
            color: '#3d3d3d'
        };

        var spinner = {
            start: startSpinner,
            stop: stopSpinner
        };

        return spinner;

        function startSpinner(selector, context, options) {
            context['loading'] = true;
            options = $.extend(options || {}, defaultOptions);
            $(selector).spin(options);
        }

        function stopSpinner(selector, context) {
            $(selector).data('spinner').stop();
            context['loading'] = false;
        }

    }
})(window.angular, window.jQuery);
