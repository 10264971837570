(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.correction').controller('Correction.GridCtrl', GridController);

    /* @ngInject */
    function GridController ($state, gettext, ngDialog, session, gridBuilder, Correction) {
        var vm = this;

        vm.dataGrid = gridBuilder.$new(
            {
                sorting: {
                    'created': 'desc'
                },
                filter: {
                  'status': 'new'
                }
            },
            Correction,
            {
                status: gettext('Status'),
                userDisplayName: gettext('Name'),
                created: gettext('Date'),
                subject: gettext('Subject'),
                reviewer: gettext('In behandeling bij'),                
                actions: gettext('Actions')
            }
        );
    }
})(window.angular);
