(function (angular, _) {
    'use strict';

    angular.module('McoCollectionPortal.memorix').filter('hiddenFilter', HiddenFilter);

    /* @ngInject */
    function HiddenFilter() {
        return function (selection) {
            return _.filter(
                selection,
                function (item) {
                    return !_.isUndefined(item.hidden) && (item.hidden === true);
                }
            );
        };
    }

})(window.angular, window._);
