(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.announcement', [])
        .config( routeConfig );

    /* @ngInject */
    function routeConfig($stateProvider) {
        $stateProvider
            .state('mco.announcements', {
                url: '/announcements',
                views: {
                    '': {
                        templateUrl: 'announcement/views/index.html'
                    },
                    'announcementList@mco.announcements': {
                        templateUrl: 'announcement/views/partials/grid.html',
                        controller: 'Announcement.GridCtrl',
                        controllerAs: 'grid'
                    }
                },
                access: {
                    isAdmin: true
                }
            });
    }

})(window.angular);
