(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.dashboard', [])
        .config( routeConfig );

    /* @ngInject */
    function routeConfig($stateProvider) {
        $stateProvider
            .state('mco.dashboard', {
                url: '/dashboard',
                views: {
                    '': {
                        templateUrl: 'dashboard/views/index.html'
                    },
                    'dashboardContext@mco.dashboard':{
                        templateUrl: 'dashboard/views/partials/context.html',
                        controller: 'Dashboard.ContextCtrl',
                        controllerAs: 'context'
                    },
                    'dashboardButtons@mco.dashboard': {
                        templateUrl: 'dashboard/views/partials/buttons.html',
                        controller: 'Dashboard.ButtonsCtrl',
                        controllerAs: 'buttons'
                    },
                    'dashboardSearch@mco.dashboard': {
                        templateUrl: 'dashboard/views/partials/search.html',
                        controller: 'Dashboard.SearchCtrl',
                        controllerAs: 'search'
                    },
                    'dashboardAnnouncements@mco.dashboard': {
                        templateUrl: 'announcement/views/partials/overview.html',
                        controller: 'Announcement.OverviewCtrl',
                        controllerAs: 'announcements'
                    }
                },
                access: {
                    isLogged: true
                }
            });
    }

})(window.angular);
