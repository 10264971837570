(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.memorix').service('EntryList', EntryList);

    /* @ngInject */
    function EntryList (resourceBuilder) {
        var persist = this;

        return resourceBuilder.$new(
            'entrylist',
            {id: '@_id'},
            {},
            {
            }
        );
    }
})(window.angular);
