(function (angular) {
    angular.module('McoCollectionPortal').constant('CONFIG', {
        api: {
            url: '@API_URL@'
        },
        mediabank: {
            base: '@MEDIABANK_BASE@'
        },
        imageServer: {
            url: '@IMAGE_SERVER_URL@',
            download: '@IMAGE_SERVER_DOWNLOAD_URL@'
        },
        monumentRegister: {
            url: 'https://monumentenregister.cultureelerfgoed.nl/monumenten/__CONSTITUENTID__' // jshint ignore:line
        },
        memorixInstitution: {
            url: '@MEMORIX_INSTITUTION_URL@' // jshint ignore:line
        },
        memorixObject: {
            url: '@MEMORIX_OBJECT_URL@' // jshint ignore:line
        }
    });
})(window.angular);
