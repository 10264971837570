(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.memorix', [])
        .config( routeConfig );

    /* @ngInject */
    function routeConfig($stateProvider) {
        $stateProvider
            .state('mco.objects', {
                url: '/objects',
                views: {
                    '': {
                        templateUrl: 'memorix/views/wizard.html',
                        controller: 'Memorix.ObjectsCtrl',
                        controllerAs: 'ctrl'
                    },
                    'wizardStep@mco.objects': {
                    }
                },
                access: {
                    isLogged: true
                }
            })
            .state('mco.objects.step1', {
                url: '/step1',
                views: {
                    'wizardStep@mco.objects': {
                        templateUrl: 'memorix/views/objects/1_object.html'
                    }
                },
                access: {
                    isLogged: true
                }
            })
            .state('mco.objects.step2', {
                url: '/step2',
                views: {
                    'wizardStep@mco.objects': {
                        templateUrl: 'memorix/views/objects/2_media.html'
                    }
                },
                access: {
                    isLogged: true
                }
            })
            .state('mco.objects.step3', {
                url: '/step3',
                views: {
                    'wizardStep@mco.objects': {
                        templateUrl: 'memorix/views/objects/3_evaluation.html'
                    }
                },
                access: {
                    isLogged: true
                }
            })
            .state('mco.objects.step4', {
                url: '/step4',
                views: {
                    'wizardStep@mco.objects': {
                        templateUrl: 'memorix/views/objects/4_recap.html'
                    }
                },
                access: {
                    isLogged: true
                }
            })
            .state(
                'mco.objectbank',
                {
                    url: '/mediabank/',
                    views: {
                        '': {
                            templateUrl: 'memorix/views/mediabank.html',
                            controller: 'Memorix.MediabankCtrl',
                            controllerAs: 'ctrl'
                        }
                    },
                    access: {
                        isLogged: true
                    }
                }
            )
            .state(
                'mco.objectbank.gallery',
                {
                    url: 'gallery/',
                    views: {
                        '': {
                            templateUrl: 'memorix/views/mediabank.html',
                            controller: 'Memorix.MediabankCtrl',
                            controllerAs: 'ctrl'
                        }
                    },
                    access: {
                        isLogged: true
                    }
                }
            )
            .state(
                'mco.objectbank.detail',
                {
                    url: 'detail?page&rm&fq&asset',
                    views: {
                        '': {
                            templateUrl: 'memorix/views/mediabank.html',
                            controller: 'Memorix.MediabankCtrl',
                            controllerAs: 'ctrl'
                        }
                    },
                    access: {
                        isLogged: true
                    }
                }
            )
            .state(
                'mco.objectbank.detail2',
                {
                    url: 'detail/?page&rm&fq&asset',
                    views: {
                        '': {
                            templateUrl: 'memorix/views/mediabank.html',
                            controller: 'Memorix.MediabankCtrl',
                            controllerAs: 'ctrl'
                        }
                    },
                    access: {
                        isLogged: true
                    }
                }
            )
            .state(
                'mco.objectbank.detail3',
                {
                    url: 'detail/{detail}/media/{media}',
                    views: {
                        '': {
                            templateUrl: 'memorix/views/mediabank.html',
                            controller: 'Memorix.MediabankCtrl',
                            controllerAs: 'ctrl'
                        }
                    },
                    access: {
                        isLogged: true
                    }
                }
            );


        $stateProvider
            .state('mco.evaluation', {
                url: '/evaluation?objectId&objectNumber',
                views: {
                    '': {
                        templateUrl: 'memorix/views/wizard.html',
                        controller: 'Memorix.EvaluationCtrl',
                        controllerAs: 'ctrl'
                    },
                    'wizardStep@mco.evaluation': {
                    }
                },
                access: {
                    isLogged: true
                }
            })
            .state('mco.evaluation.step1', {
                url: '/step1',
                views: {
                    'wizardStep@mco.evaluation': {
                        templateUrl: 'memorix/views/objects/3_evaluation.html'
                    }
                },
                access: {
                    isLogged: true
                }
            })
            .state('mco.evaluation.step2', {
                url: '/step2',
                views: {
                    'wizardStep@mco.evaluation': {
                        templateUrl: 'memorix/views/objects/5_recap_evaluation.html'
                    }
                },
                access: {
                    isLogged: true
                }
            });

        $stateProvider
            .state('mco.edit', {
                url: '/edit?objectId&objectNumber&touchback',
                views: {
                    '': {
                        templateUrl: 'memorix/views/wizard.html',
                        controller: 'Memorix.EditCtrl',
                        controllerAs: 'ctrl'
                    },
                    'wizardStep@mco.edit': {
                    }
                },
                access: {
                    isLogged: true
                }
            })
            .state('mco.edit.step1', {
                url: '/step1',
                views: {
                    'wizardStep@mco.edit': {
                        templateUrl: 'memorix/views/objects/1_edit.html'
                    }
                },
                access: {
                    isLogged: true
                }
            })
            .state('mco.edit.step2', {
                url: '/step2',
                views: {
                    'wizardStep@mco.edit': {
                        templateUrl: 'memorix/views/objects/2_media.html'
                    }
                },
                access: {
                    isLogged: true
                }
            })
            .state('mco.edit.step3', {
                url: '/step3',
                views: {
                    'wizardStep@mco.edit': {
                        templateUrl: 'memorix/views/objects/3_edit_recap.html'
                    }
                },
                access: {
                    isLogged: true
                }
            });
    }
})(window.angular);
