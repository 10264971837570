(function (angular, _) {
    'use strict';

    angular.module('McoCollectionPortal.memorix').controller('Memorix.EvaluationCtrl', EvaluationCtrl);

    /* @ngInject */
    function EvaluationCtrl ($sce, $state, $stateParams, gettext, MmxWizard, User, Monument, Evaluation,
                             urlHelper, messageService) {
        var vm = this;
        var user = new User();
        vm.user = user.identity();
        vm.objectId = $stateParams.objectId;
        vm.objecTitle = $stateParams.objectNumber;
        vm.wizard = MmxWizard.$new(
            'mco.evaluation',
            [
                createEvaluationStep(),
                createRecapStep()
            ],
            {
                'model': Monument
            }
        );

        vm.wizard.submit = evaluationSubmit;

        activate();

        function activate() {
            vm.wizard.setActiveStep();
        }

        function processStepThree() {
            /*jshint validthis:true */
            var self = this;
            var isValid = true;
            _.each(
                this.cursor.elements[0]['elements'],
                function (question) {
                    if (question.satisfied === true && _.isEmpty(self.cursor.data[question.name + 'Note'])) {
                        question.error = true;
                        isValid = false;
                    } else {
                        question.error = false;
                    }
                }
            );
            this.cursor.data['questions'] = this.cursor.elements;
            return isValid;
        }

        function prepareStepFour() {
            /*jshint validthis:true */
            var self = this;
            var evaluationData = _.omit(
                this.steps[0].data,
                'questions'
            );
            evaluationData['questions'] = this.steps[0].data['questions'][0]['elements'];

            evaluationData['questions'] = _.map(
                this.steps[0].data['questions'][0]['elements'],
                function (q) {
                    q['options'] = _.omit(
                        q['options'],
                        'tooltip'
                    );
                    return q;
                }
            );

            var evaluation = new Evaluation();
            evaluation.rawRequest(
                'evaluation',
                'POST',
                {},
                evaluationData
            ).then(
                function (response) {
                    self.current_score = !_.isUndefined(response.data.evaluation.current_score) &&
                        response.data.evaluation.current_score !== ''?
                        response.data.evaluation.current_score :
                        gettext('Waardebepaling niet ingevuld');
                    self.historical_score = !_.isUndefined(response.data.evaluation.historical_score) &&
                        response.data.evaluation.historical_score !== ''?
                        response.data.evaluation.historical_score :
                        gettext('Waardebepaling niet ingevuld');
                    self.steps[0].evaluation = response.data.evaluation;
                    self.evaluation_level = _.find(
                        evaluationData,
                        function (item) {
                            return _.isString(item) && item.indexOf('niveau') !== -1;
                        }
                    );
                }
            );
        }

        function createEvaluationStep() {
            var evaluationStep = {
                name: 'evaluation',
                cssClass: 'valuation-form',
                help: urlHelper.getBaseUrl() + '/download/evaluation',
                helpLabel: gettext('Evaluation Procedure Guidelines'),
                label: gettext('Waardebepaling ') + $stateParams.objectNumber,
                elements: [],
                process: processStepThree,
                clear: function () {
                    vm.wizard.cursor.data = {};
                    angular.forEach(
                        vm.wizard.cursor.elements[0].elements,
                        function (el) {
                            el.satisfied = false;
                        }
                    );
                }
            };

            var evaluation = new Evaluation();

            evaluation.rawRequest(
                'evaluation',
                'get',
                {},
                {}
            ).then(
                function (response) {
                    var elements = [];
                    var template = 'evaluation/expert';
                    if (!vm.user.isExpert() && !vm.user.isAdmin()) {
                        template = 'evaluation/regular';
                        vm.wizard.recapTooltip = 'U heeft een aantal vragen beantwoord die iets zeggen over de betekenis van het voorwerp. U heeft per vraag aangegeven hoe u de betekenis beoordeelt: \'laag\', \'midden\' of ‘\'hoog\', of dat u het niet wist. Uw scores zijn automatisch vertaald naar een eindscore. Deze eindscore is opgedeeld in de huidige betekenis en de historische betekenis.' // jshint ignore:line
                    } else {
                        vm.wizard.recapTooltip = 'Hier vindt u de eindscore van de zojuist ingevulde waardebepaling. De eindscore wordt automatisch berekend.<br /><br />De eerste twee vragen, over de historische en artistieke waarde, geven het voorwerp zijn primaire betekenis. In de eindscore komt dit tot uiting in de score: \'laag\', \'medium\' of \'hoog\'.<br /><br /> De volgende drie vragen gaan over vergelijkende criteria: zeldzaamheid, fysieke staat en ensemblewaarde. Deze criteria kunnen de primaire betekenis van een voorwerp beïnvloeden, maar nooit wezenlijk veranderen.<br /><br /> De beïnvloeding van de vergelijkende criteria op de primaire betekenis van een voorwerpen laten we zien met een ‘+’ of een ‘–’ achter de score. Een voorbeeld: vanwege de historische en artistieke waarde scoort een voorwerp ‘hoog’ (primaire betekenis). Doordat het niet zeldzaam blijkt te zijn en ook niet hoog scoort op fysieke staat en ensemblewaarde, wordt de primaire betekenis enigszins beïnvloed door de vergelijkende criteria. De eindscore is dan: ‘hoog–’.<br /><br /> Beïnvloeden de vergelijkende criteria de primaire betekenis niet? Dan blijft een ‘+’ of ‘–’ vanzelfsprekend achterwege.';  // jshint ignore:line
                    }

                    if (typeof response.data.questions !== 'undefined') {
                        angular.forEach(
                            response.data.questions,
                            function (question, index) {
                                var element = {
                                    'name': question['uuid'],
                                    'template': 'radiolist',
                                    'label': question['text'],
                                    'satisfied': false,
                                    'options' : {
                                        'choices': []
                                    }
                                };

                                if (question['tooltip'] !== '' && question['tooltip'] !== null) {
                                    element['options']['tooltip'] = $sce
                                        .trustAsHtml(
                                            question['tooltip'].replace(/(?:\r\n|\r|\n)/g, '<br />')
                                        );
                                }

                                if (index === 6 && question.answers.length === 4) {
                                    element['options']['tooltip0'] = 'Ensemble bepaald door de samenhang in continuïteit van bezit en gebruik'; // jshint ignore:line
                                    element['options']['tooltip1'] = 'Ensemble bepaald door de samenhang van samenstelling of vervaardiging in één periode, maar niet volgens een integraal architectonisch concept'; // jshint ignore:line
                                    element['options']['tooltip2'] = 'Ensemble bepaald door de samenhang van een integraal architectonisch concept, een gesamtkunstwerk'; // jshint ignore:line
                                    element['options']['tooltip3'] = 'Ensemble bepaald door de samenhang van een van de vorige ensemblevormen. De onderdelen van dit ensemble bevinden zich echter niet langer op de oorspronkelijke locatie.';  // jshint ignore:line
                                    question['answers'] = question.answers.reverse();
                                }

                                angular.forEach(
                                    question['answers'],
                                    function (answer) {
                                        var isSatisfied = false;
                                        if (answer['text'] === 'slecht' ||
                                            (answer['weight'] > 10 &&
                                             answer['text'] !== 'goed')) {
                                            isSatisfied = true;
                                        }
                                        element['options']['choices'].push(
                                            {
                                                'value': answer['weight'],
                                                'label': answer['text'],
                                                'satisfied': isSatisfied
                                            }
                                        );
                                    }
                                );
                                elements.push(element);
                            }
                        );

                        if (typeof elements[7] !== 'undefined') {
                            elements[7].options['vertical'] = true;
                        }

                        vm.wizard.getStepByMagic(
                            function (result, current) {
                                return current.order === 1;
                            }
                        ).setElements(
                            [
                                {
                                    'template': template,
                                    'elements': elements
                                }
                            ]
                        );
                    }
                }
            );
            return evaluationStep;
        }

        function createRecapStep() {
            return {
                label: gettext('Recap'),
                notEmpty: function (container, value) {
                    var empty = false;
                    if (typeof container[value] === 'undefined') {
                        empty = true;
                    } else {
                        if (container[value] === null || container[value] === '') {
                            empty = true;
                        }
                        if (_.isArray(container[value]) && container[value].length === 0) {
                            empty = true;
                        }
                    }
                    return !empty;
                },
                prepare: prepareStepFour,
            };
        }

        function evaluationSubmit() {
            /*jshint validthis:true */
            var self = this;
            var wizardData = {};
            angular.forEach(
                this.steps,
                function (step) {
                    if (!step.skip) {
                        if (step.name !== null)  {
                            wizardData[step.name] = step.data;
                        }
                    }
                }
            );

            var evaluationData = _.omit(
                wizardData['evaluation'],
                'questions'
            );

            evaluationData['questions'] = wizardData['evaluation']['questions'][0]['elements'];
            evaluationData['objectId'] = vm.objectId;
            var evaluation = new Evaluation();
            this.loading = true;
            evaluation.rawRequest(
                'evaluate',
                'POST',
                {},
                evaluationData
            ).then(
                function (response) {
                    if (typeof response.data.status !== 'undefined' && response.data.status === 'ok') {
                        self.submitted = true;
                    } else {
                        self.failed= true;
                    }
                    self.loading = false;
                }

            );
        }
    }
})(window.angular, window._);
