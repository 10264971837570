(function (angular, _) {
    'use strict';

    angular.module('McoCollectionPortal.memorix').controller('Memorix.ObjectsCtrl', ObjectsCtrl);
    /* jshint maxparams: 20 */
    /* @ngInject */
    function ObjectsCtrl ($sce, $state, gettext, gettextCatalog, MmxWizard, User, Monument, Evaluation,
                          EntryList, TmpFile, urlHelper, messageService) {
        var vm = this;
        var user = new User();
        vm.user = user.identity();

        vm.dropzoneConfig = {};
        vm.wizard = MmxWizard.$new(
            'mco.objects',
            [
                createRegistrationStep(),
                createFilesStep(),
                createEvaluationStep(),
                createRecapStep()
            ],
            {
                'model': Monument,
                'successMsg': gettextCatalog.getString('Gegevens succesvol opgeslagen.'),  // jshint ignore:line
                'successTitle': gettextCatalog.getString('Voorwerp registratie afgerond'),
                'successCb': function () {
                    $state.go('mco.dashboard');
                }
            }
        );

        activate();

        function activate() {
            vm.wizard.setActiveStep();

            vm.dropzoneConfig = {
                'options': {
                    'init': function () {
                        var dz = this;
                        angular.forEach(
                            vm.wizard.cursor.files,
                            function (f) {
                                var fileReader = new FileReader();
                                f['thumbnnailDataUri'] = dz.createThumbnail(f);
                                dz.emit('addedfile', f);
                                dz.emit('thumbnail', f, f['thumbnnailDataUri']);
                                dz.emit('complete', f);
                            }
                        );
                    },
                    'url': urlHelper.getFilesUrl(),
                    'maxFilesize': 10,
                    'addRemoveLinks': true,
                    'uploadMultiple': false,
                    'headers': {
                        'Authorization': 'Bearer ' + vm.user.getToken()
                    },
                    dictDefaultMessage: 'Sleep bestanden hier om te uploaden',
                    dictFallbackMessage: 'Uw browser ondersteunt geen drag & drop uploaden',
                    dictRemoveFile: 'Afbeelding verwijderen'
                },
                'eventHandlers': {
                    'sending': function (file, xhr, formData) {

                    },
                    'success': function (file, response) {
                        if (response.fileId) {
                            file._id = response.fileId;
                            vm.wizard.cursor.data.files.push(response.fileId);
                            vm.wizard.cursor.fileCount++;
                            vm.wizard.cursor.files.push(file);
                        }

                        if (typeof response.msg !== 'undefined' && response.msg !== 'http_success') {
                            messageService.$raise(response.msg, response.msg);
                        }
                        return response;
                    },
                    'removedfile': function (file) {
                        var tmpFile = new TmpFile(file);
                        tmpFile.$remove(
                            function (response) {
                                if (response.data.status === 'ok') {
                                    vm.wizard.cursor.data.files = _.without(
                                        vm.wizard.cursor.data.files,
                                        file._id
                                    );

                                    vm.wizard.cursor.files = _.filter(
                                        vm.wizard.cursor.files,
                                        function (f) {
                                            return file._id !== f._id;
                                        }
                                    );
                                    vm.wizard.cursor.fileCount--;
                                }
                            }
                        );
                    }
                }
            };
        }

        function processStepOne() {
            /*jshint validthis:true */
            function customDate(dateObject) {
                var dateValue = [];
                _.each(
                    ['year', 'month', 'day'],
                    function (part) {
                        var defaultValue = part === 'year'? '0000': '00';
                        dateValue.push( _.isUndefined(dateObject[part]) || _.isEmpty(dateObject[part]) ?
                                        defaultValue : dateObject[part]);
                    }
                );
                return dateValue.join('-');
            }

            this.cursor.data.institution = _.pick(vm.user.getContext(), ['id', 'title']);

            this.cursor.data['conditions'] = [
                {
                    'condition': this.cursor.data.condition,
                    'clarification': this.cursor.data.clarification
                }
            ];

            ///////////////////////////////////////////////////////////////////
            // this.cursor.data['dimensions'] = [                            //
            //     {                                                         //
            //         'height': this.cursor.data.height,                    //
            //         'width': this.cursor.data.width,                      //
            //         'description': this.cursor.data.dimensionsDescription //
            //     }                                                         //
            // ];                                                            //
            ///////////////////////////////////////////////////////////////////

            this.cursor.data['dates'] = [
                {
                    'earliest': {
                        'year': this.cursor.data.earliest
                    },
                    'latest': {
                        'year': this.cursor.data.latest
                    },
                    'circa': this.cursor.data.circa,
                    'remark': this.cursor.data.remark
                }
            ];

            _.each(
                this.cursor.data['dates'],
                function (record) {
                    record['earliest-value'] = customDate(record['earliest']);
                    record['latest-value'] = customDate(record['latest']);
                }
            );

            var d = new Date();
            var month = (d.getMonth() + 1).toString();
            if (month.length === 1) {
                month = '0' + month;
            }
            var day = d.getDate().toString();
            if (day.length === 1) {
                day = '0' + day;
            }

            this.cursor.data['locations'] = [
                {
                    'constituent':  this.cursor.data.institution,
                    'date': d.getFullYear().toString() + '-' + month + '-' + day,
                    'location': this.cursor.data.location
                }
            ];
        }

        function prepareStepTwo () {
            /*jshint validthis:true */
            if (typeof this.cursor.data.files === 'undefined' || !_.isArray(this.cursor.data.files)) {
                this.cursor.data.files = [];
            }
            if (typeof this.cursor.files === 'undefined' || !_.isArray(this.cursor.files)) {
                this.cursor.files = [];
            }
            return this;
        }

        function processStepThree() {
            /*jshint validthis:true */
            var self = this;
            var isValid = true;
            _.each(
                this.cursor.elements[0]['elements'],
                function (question) {
                    if (question.satisfied === true && _.isEmpty(self.cursor.data[question.name + 'Note'])) {
                        question.error = true;
                        isValid = false;
                    } else {
                        question.error = false;
                    }
                }
            );
            this.cursor.data['questions'] = this.cursor.elements;
            return isValid;
        }

        function prepareStepFour() {
            /*jshint validthis:true */
            var self = this;
            var evaluationData = _.omit(
                this.steps[2].data,
                'questions'
            );
            evaluationData['questions'] = _.map(
                this.steps[2].data['questions'][0]['elements'],
                function (q) {
                    q['options'] = _.omit(
                        q['options'],
                        'tooltip'
                    );
                    return q;
                }
            );

            var evaluation = new Evaluation();
            evaluation.rawRequest(
                'evaluation',
                'POST',
                {},
                evaluationData
            ).then(
                function (response) {
                    self.current_score = !_.isUndefined(response.data.evaluation.current_score) &&
                        response.data.evaluation.current_score !== ''?
                        response.data.evaluation.current_score :
                        gettext('Waardebepaling niet ingevuld');
                    self.historical_score = !_.isUndefined(response.data.evaluation.historical_score) &&
                        response.data.evaluation.historical_score !== ''?
                        response.data.evaluation.historical_score :
                        gettext('Waardebepaling niet ingevuld');
                    self.steps[2].evaluation = response.data.evaluation;
                    self.evaluation_level = _.find(
                        evaluationData,
                        function (item) {
                            return _.isString(item) && item.indexOf('niveau') !== -1;
                        }
                    );
                }
            );
        }

        function createRegistrationStep() {
            var generalFieldset = {
                'template': 'fieldset',
                'legend': gettext('Description'),
                'elements': [
                    {
                        'name': 'constituent',
                        'template': 'autocomplete',
                        'inline': 'institution',
                        'label': gettext('Institution'),
                        'required': true,
                        'url': urlHelper.getAutocompleteUrl('allowedinstitutions'),
                        'title': 'title',
                        'placeholder': gettext('Institution autocomplete'),
                        'defaultValue': vm.user.getContext().title
                    },
                    {
                        'name': 'location',
                        'template': 'text',
                        'label': gettext('Situering'),
                        'options': {
                            'tooltip': "Hier vult u in waar het voorwerp zich in de kerk bevindt. Bijvoorbeeld: 'koor', 'sacristie', 'linker zijbeuk', 'lade in credenskast', 'consistoriekamer', etc. Ook te gebruiken voor bijgebouwen als: 'pastorie', 'wijkgebouw', 'jeugdhonk'." // jshint ignore:line
                        }
                    },
                    {
                        'name': 'objectname',
                        'template': 'thesaurus',
                        'inline': 'thesaurus',
                        'label': gettext('Object name'),
                        'title': 'label',
                        'placeholder': gettext('Kies objectnaam'),
                        'url': urlHelper.getAutocompleteUrl('thesaurus', 'type=objectname&'),
                        'options': {
                            'tooltip': "Hier vult u de meest specifieke benaming van een voorwerp in. U geeft met een term aan om welk soort voorwerp het gaat. Het is heel belangrijk om steeds dezelfde term voor hetzelfde voorwerp te gebruiken. Als u naar een specifieke term zoekt, kunt u gebruik maken van de Woordenlijst Religieuze Voorwerpen op www.catharijneconvent.nl.<br /><br />Het veld objectnaam is gekoppeld aan de erfgoedthesaurus van de Rijksdienst voor het Cultureel Erfgoed (www.erfgoedthesaurus.nl). U typt het begin van de objectnaam en kiest vervolgens de juiste term. De erfgoedthesaurus gebruikt meervoudsvormen, dit sluit aan bij andere (internationale) thesauri en zegt niets over het aantal. Voorbeeld: u beschrijft een avondmaalskan, dan kiest u uit de lijst: ‘avondmaalskannen’; of bij de registratie van een kazuifel kiest u: ‘kazuifels’.<br /><br />Het veld is herhaalbaar, u kunt dus meerdere objectnamen aan een voorwerp toekennen.<br /><br />Voorbeeld: bij een reisavondmaalsstel kunt u de volgende objectnamen toevoegen: draagkoffers, avondmaalsschalen, avondmaalskannen en avondmaalsbekers. Dit geldt bijvoorbeeld ook voor een kelk die samen met een kelklepeltje en een pateen in een foedraal worden bewaard (kelken, kelklepeltjes, patenen en foedralen)."  // jshint ignore:line
                        }
                    },
                    {
                        'name': 'numberofpieces',
                        'template': 'text',
                        'label': gettext('Number of pieces'),
                        'class': 'input-size-4',
                        'options': {
                            'tooltip': "Hier geeft u het totaal aantal exemplaren van het voorwerp en/of het aantal onderdelen waaruit een bij elkaar horende groep voorwerpen bestaat op.<br /><br />Voorbeelden: beschrijft u zes kandelaars die bij elkaar horen, vermeld hier dan ‘6’; een avondmaalsservies kan bestaan uit twaalf stukken: ‘12’."  // jshint ignore:line
                        }
                    },
                    {
                        'name': 'title',
                        'template': 'text',
                        'label': gettext('Title'),
                        'options': {
                            'tooltip': "Het veld ‘titel’ kan de originele titel van het voorwerp zijn, of toegekende titel die als korte, kernachtige beschrijving van het voorwerp fungeert.<br /><br />Voorbeelden van een originele titel zijn: ‘Christus aan het kruis’ (schilderij), ‘Dominee De Vries’ (portret), ‘H. Antonius van Padua met Kind’ (beeld).<br /><br />Voorbeelden van korte beschrijvingen die als titel kunnen dienen: ‘Koperen kandelaar’, ‘Houten kruis met ivoren corpus’, ‘Barokke preekstoel’."  // jshint ignore:line
                        }
                    },
                    {
                        'name': 'description',
                        'template': 'textarea',
                        'label': gettext('Description'),
                        'options': {
                            'tooltip': "Hier kunt u het voorwerp uitgebreider beschrijven.<br /><br />                Voorbeelden: ‘Kroonluchter met zestien armen in twee etages. Boven op de stam een beeldje van een ridder (het wapen tussen zijn handen ontbreekt), onderaan een dubbele dierenkop met ring. Koperen stang met vijf bollen.’ Of: ‘Doopboog in de vorm van een tak met uitstulpingen. Beide uiteinden eindigen onderaan in een vissenkop.’<br /><br />U kunt hier ook inscripties noteren. Voorbeeld: “VOOR DE DIACONYE VAN ROSENDAEL 1678”. Neem de inscriptie zo letterlijk mogelijk over, bijvoorbeeld door goed te letten op hoofdletters en kleine letters. Gebruik dubbele aanhalingstekens om duidelijk te maken dat het een letterlijk overgenomen inscriptie betreft.<br /><br />Een belangrijke aanvulling op de beschrijving is het toevoegen van één of meerdere goede (detail)foto’s. In stap 2 van de voorwerpregistratie kunt u afbeeldingen toevoegen." // jshint ignore:line
                        }
                    },
                    {
                        'name': 'material',
                        'template': 'thesaurus',
                        'inline': 'thesaurus',
                        'label': gettext('Material'),
                        'title': 'label',
                        'placeholder': gettext('Kies materiaal'),
                        'url': urlHelper.getAutocompleteUrl('thesaurus', 'type=material&'),
                        'options': {
                            'tooltip':  "Hier vult u het materiaal in waarvan het voorwerp of een deel van het voorwerp is gemaakt. Dit veld is herhaalbaar en u kunt dus van meerdere onderdelen aangeven uit welk materiaal het bestaat. Als u niet zeker bent van welk materiaal een voorwerp is gemaakt dan kunt u het beste een algemenere term gebruiken (bijvoorbeeld ‘textiel’ i.p.v. ‘katoen’; of ‘metaal’ in plaats van ‘koper’) of het invullen achterwege laten. Het veld materiaal is gekoppeld aan de Erfgoedthesaurus van de Rijksdienst voor het Cultureel Erfgoed (www.erfgoedthesaurus.nl). U typt het begin van de naam van het materiaal en kiest de juiste term.<br /><br />Voorbeeld: een preekstoel heeft een eikenhouten kuip, een Belgisch hardstenen trap en een gevlekt marmeren basis. U vult dan in: ‘eikenhout’, ‘Belgische hardsteen’ en ‘gevlekt marmer’.<br /><br />Als u iets wilt zeggen over de techniek dan kunt u dit invullen bij de beschrijving. Een voorbeeld is het veel voorkomende ‘verguld zilver’ of ‘verguld koper’ (waarbij de materialen zilver en koper zijn, en vergulden de techniek). Het herkennen van een techniek vereist wel kennis op dit gebied." // jshint ignore:line
                        }
                    }
                ]
            };

            var locationsFieldset = {
                'template': 'fieldset',
                'legend': gettext('Location'),
                'hidden': true,
                'elements': [
                    {
                        'name': 'locations',
                        'template': 'entities/locations',
                        'inline': 'entities/locations',
                        'label': gettext('Location'),
                        'list': 'status',
                        'options': {
                            'placeholderInstitution': gettext('Institution'),
                            'autocompleteInstitution': urlHelper.getAutocompleteUrl('allowedinstitutions'),
                            'defaultRecord': {
                                'constituent': '',
                                'date': {},
                                'location': '',
                                'performed_by': '',
                                'status': ''
                            }
                        }
                    }
                ]
            };

            var detailFieldset = {
                'template': 'fieldset',
                'legend': gettext('Details'),
                'elements': [
                    {
                        'name': 'signature',
                        'template': 'textarea',
                        'label': gettext('Signature'),
                        'options': {
                            'tooltip': "Hier vult u de signatuur (‘handtekening’) in die op het voorwerp is aangebracht. Geef aan waar de signatuur zich bevindt en probeer de signatuur letterlijk over te nemen. Gebruik dubbele aanhalingstekens om duidelijk te maken dat het een letterlijk overgenomen signatuur betreft. Met de schuine streep / geeft u aan dat de tekst op een volgende regel verder gaat. U kunt hier ook labels van makers van liturgisch textiel noteren.<br /><br />Signaturen zijn vaak lastig te lezen. Neem een duidelijke detailfoto van de signatuur (zie de instructie bij het toevoegen van afbeeldingen in stap 2 van de voorwerpregistratie). Een specialist kan een signatuur aan de hand van een goede foto vaak lezen.<br /><br />Voorbeelden:<br />linksonder in de marge: “H.A. Bauer pinxit 1785”<br />label (binnenzijde rug, midden boven): \"A.W. Stadelmaier / Ateliers v. kerkelijke kunst / NIJMEGEN\"<br />rechtsonder: \"Joep Nicolas 1962\"<br />voetstuk linkerzijde: \"ST. JORIS BEESEL\"" // jshint ignore:line
                        }
                    },
                    {
                        'name': 'markings',
                        'template': 'textarea',
                        'label': gettext('Markings'),
                        'options': {
                            'tooltip': "Hier beschrijft u de zilver-, goud- of tinmerken op een voorwerp. Probeer een merk zo duidelijk mogelijk te beschrijven naar inhoud (tekens of tekst) en vorm. Geef ook aan waar het merk zich bevindt.<br /><br />Voorbeelden:<br />bovenrand: eikenblaadje in ovaal<br />op de voetrand: monogram van letters-AvR, in liggende rechthoek<br />onder de voet: letter J in cirkel<br />op de cuppa: naar rechts lopend leeuwtje met cijfer 2, in zeshoek<br />onderzijde:  \"C & K Co.\" in cirkel,  waaromheen \"TIEL / HOLLAND\" en daaromheen \"ETAIN PUR / REAL PEWTER\"<br /><br />Op (oude) zilveren voorwerpen zijn vaak meerdere merken aangebracht om de herkomst en het zilvergehalte aan te duiden. Het identificeren van deze merken is niet eenvoudig en vergt de nodige ervaring. Lukt het u niet om de merken te identificeren, vermeld dan toch hoeveel merken u heeft gezien en waar (bv. twee zilvermerken op voetrand, twee op de cuppa). Neem een duidelijke detailfoto van de merken (zie de instructie bij het toevoegen van afbeeldingen in stap 2 van de voorwerpregistratie). Een specialist kan een merk aan de hand van een goede foto vaak herkennen.<br /><br />Merken zijn erg klein en vaak alleen met een vergrootglas goed te onderscheiden. U vindt de merken meestal op de randen van een voorwerp (voetrand, rand van de cuppa), of op of in de voet. Als het voorwerp uit verschillende onderdelen bestaat (bijv. ciborie met voet, stam, cuppa en deksel) zijn de verschillende onderdelen afzonderlijk gemerkt. Let dus op wanneer u een merk op de voet van een voorwerp terugvindt. Dit betekent niet dat de overige delen van de dezelfde maker zijn, hetzelfde zilvergehalte hebben of van hetzelfde materiaal zijn gemaakt." // jshint ignore:line
                        }
                    }
                ]
            };

            var dateFieldset = {
                'template': 'fieldset',
                'legend': gettext('Date information'),
                'elements': [
                    {
                        'name': 'earliest',
                        'template': 'text',
                        'label': gettext('Earliest'),
                        'class': 'input-size-6',
                        'options': {
                            'placeholder': 'yyyy',
                            'tooltip': "Hier geeft u aan in welk jaartal het voorwerp is gemaakt. U kunt ook aangeven in welke periode een voorwerp is gemaakt. U vult dan het beginjaar van de periode in, en bij het volgende veld, ‘datering tot’, het eindjaar.<br /><br />Voorbeeld: een voorwerp is in 1824 gemaakt. U vult hier dan in: ‘1824’ en bij het veld ‘datering tot’ eveneens ‘1824’; of: een voorwerp is in de eerste helft van de 20ste eeuw gemaakt. U vult hier dan in: ‘1900’, en bij het volgende veld (‘datering tot’): ‘1950’.<br /><br />Een voorwerp kan ook uit meerdere onderdelen bestaan, die in verschillende perioden zijn gemaakt. U vult hier dan de oudste datum in en bij ‘datering tot’ de jongste datum.<br /><br />Voorbeeld: een altaar dateert uit 1876, maar in 1921 is het altaar uitgebreid met twee heiligenbeelden. U vult hier dan in: ‘1876’ en bij het volgende veld (‘datering tot’): ‘1921’.<br />Deze velden zijn van belang voor het kunnen zoeken op jaartal of periode.<br /><br />In het veld Datering beschrijft u de volledige periode waarbij u eventuele tekst tussen haakjes plaatst. Voorbeeld: ‘1876 (altaar), 1921 (twee heiligenbeelden)’ of ‘1900-1950’ of ‘1930 (circa)’.<br /><br />Het is belangrijk dat u alle velden in het blok Datering invult."  // jshint ignore:line
                        }
                    },
                    {
                        'name': 'latest',
                        'template': 'text',
                        'class': 'input-size-6',
                        'label': gettext('Latest'),
                        'options': {
                            'placeholder': 'yyyy',
                            'tooltip': "Hier kunt u de einddatum aangeven van de periode waarin een voorwerp is gemaakt. Bij ‘datering van’ vult u het beginjaar van de periode in, en hier vult u het eindjaar in.<br /><br />Voorbeeld: een voorwerp is in 1824 gemaakt. U vult hier dan in: ‘1824’ en bij het veld ‘datering van’ eveneens ‘1824’; of: een voorwerp is in de eerste helft van de 20ste eeuw gemaakt. U vult hier dan in: ‘1950’, en bij het vorige veld (‘datering tot’): ‘1900’.<br /><br />Een voorwerp kan ook uit meerdere onderdelen bestaan, die in verschillende perioden zijn gemaakt. U vult dan bij het vorige veld ‘datering van’ de oudste datum in, en hier de jongste datum.<br /><br />Voorbeeld: een altaar dateert uit 1876, maar in 1921 is het altaar uitgebreid met twee heiligenbeelden. U vult bij het vorige veld (’datering van’) in: ‘1876’ en hier: ‘1921’. Deze twee velden zijn van belang om te kunnen zoeken op jaartal of periode.<br /><br />In het veld Datering beschrijft u de volledige periode waarbij u eventuele tekst tussen haakjes plaatst. Voorbeeld: ‘1876 (altaar), 1921 (twee heiligenbeelden)’ of ‘1900-1950’ of ‘1930 (circa)’.<br /><br />Het is belangrijk dat u alle velden in het blok Datering invult." // jshint ignore:line
                        }
                    },
                    {
                        'name': 'circa',
                        'template': 'checkbox',
                        'inline' : 'circa',
                        'label': gettext('Circa'),
                        'options': {
                            'placeholder': 'yyyy',
                            'tooltip': "Als u het jaar waarin het voorwerp is gemaakt niet exact weet, maar wel een gegrond vermoeden heeft, dan kunt u dit veld aanvinken. De stelregel is dat het hier een schatting van 5 jaar eerder of later dan het daadwerkelijke jaartal betreft.<br /><br />Voorbeeld: een voorwerp is rond 1765 gemaakt (dus tussen 1760-1770). U vult dan zowel bij ‘datering van’ als bij ‘datering tot’ in: ‘1765’, en vinkt hier ‘circa’ aan.<br /><br />In het veld Datering beschrijft u tenslotte de volledige periode: ‘1765 (circa)’.<br /><br />Het is belangrijk dat u alle velden in het blok Datering invult." // jshint ignore:line
                        }
                    },
                    {
                        'name': 'remark',
                        'template': 'text',
                        'label': gettext('Datering'),
                        'options': {
                            'tooltip': "Hier vult u de volledige datering van het voorwerp en/of de verschillende onderdelen in.<br /><br />Voorbeeld:<br />'1924'<br />'1950 (circa)'<br />'1900-1950'<br />'1876 (altaar), 1921 (twee heiligenbeelden)'<br /><br />Vergeet u niet ook de velden 'datering van', 'datering tot' en circa in te vullen. Deze velden zijn belangrijk om later goed te kunnen zoeken in de collectie." // jshint ignore:line

                        }
                    }
                ]
            };

            var measurementsFieldset = {
                'template': 'fieldset',
                'legend': gettext('Dimensions'),
                'elements': [
                    {
                        'name': 'dimensions',
                        'template': 'text',
                        'label': gettext('Dimensions'),
                        'options': {
                            'tooltip': "Hier vult u eerst de hoogte van het voorwerp in, en dan de breedte. Vervolgens vult u eventueel de diepte of de diameter in. U geeft de afmetingen weer in centimeters, met maximaal één decimaal achter de komma. U meet het hoogste of het breedste punt van een voorwerp.<br /><br />Voorbeeld hoogte: bij een beeld van de Heilige Michael en de draak meet u vanaf het vloeroppervlak tot bijvoorbeeld de bovenzijde van de speer.<br />Voorbeeld breedte: bij een avondmaalskan meet u vanaf de bolling van de buik tot het buitenste stuk van het oor.<br /><br />Sommige voorwerpen zijn erg groot of moeilijk te bereiken. U kunt dan een schatting maken waarbij u bij voorkeur een referentiepunt gebruikt, zoals een al opgemeten voorwerp in de buurt, of u kunt bijvoorbeeld het aantal bakstenen op de achtergrond tellen.<br /><br /> U kunt van meerdere onderdelen de afmetingen registreren. Noteer telkens om welk onderdeel het gaat.<br /><br />Voorbeeld:<br />‘beeld: h. 125 cm, b. voet 35,5 cm, d. voet 30 cm’<br />‘kelk: h. 15 cm, diam. voet 15,5 cm; diam. cuppa 12 cm; pateen: diam. 14,5 cm;<br /> lepeltje: h. 12 cm; foedraal: h. 18 x b. 21,5 x d. 18 cm’"  // jshint ignore:line
                        }
                    }
                ]
            };
            var conditionFieldset = {
                'template': 'fieldset',
                'legend': 'Condition',
                'elements': [
                    {
                        'name': 'condition',
                        'label': gettext('Condition'),
                        'template': 'controllist',
                        'inline': 'controllist',
                        'list': 'conditions',
                        'options': {
                            'tooltip': "Hier geeft u de toestand aan waarin het voorwerp zich bevindt. Geef aan of het voorwerp in een goede, redelijke of slechte staat is.<br /><br />Goed: geen beschadiging of verval.<br />Redelijk: kleine beschadigingen maar geen actief of slechts langzaam verval<br />Slecht: behoorlijke schade, restauratie/conservering is noodzakelijk, het voorwerp kan niet langer zonder bijkomende schade worden gehanteerd.<br /><br />Het is niet altijd eenvoudig om de juiste toestand van een voorwerp te bepalen. Schade aan voorwerpen kan zeer divers van aard zijn. Elk voorwerp en materiaalsoort heeft zijn eigen, specifieke schadebeelden. Het vaststellen van de toestand vereist dus vaak specifieke kennis. Bovendien kan de fysieke staat wijzigen, bijvoorbeeld wanneer een voorwerp is gerestaureerd." // jshint ignore:line
                        }
                    },
                    {
                        'name': 'clarification',
                        'template': 'text',
                        'label': gettext('Clarification'),
                        'options': {
                            'tooltip': "Hier kunt u bijzonderheden of toelichtingen over de toestand van het voorwerp noteren. U kunt hier ook noteren of een voorwerp compleet is of niet. Vermeldt u hier ook alle gegevens met betrekking tot reparaties, conserveringsmaatregelen of restauraties (wat, wanneer en door wie).<br /><br />Voorbeeld:<br />‘Verguldsel pateen sleets aan onderzijde; krassen midden op onderzijde pateen’<br />’Doek van het portret heeft rechtsboven vouwen; scheurtje in linkerbovenhoek; kale plekken (vochtschade) langs onderrand’<br />‘Vier van de zeven kaarsenpinnen ontbreken’<br />‘Circa 1875 gerestaureerd door H. van der Geld’<br />‘Handen van Kind in 1976 bijgemaakt door atelier A. Pietersen’"// jshint ignore:line
                        }
                    }
                ]
            };

            var additionalFieldset = {
                'template': 'fieldset',
                'legend': gettext('Additional information'),
                'elements': [
                    {
                        'name': 'origin',
                        'template': 'text',
                        'label': gettext('Acquisition and origin'),
                        'options': {
                            'tooltip': "Hier kunt u invullen hoe en wanneer het voorwerp in bezit of onder beheer van uw kerk is gekomen. Noteert u ook de naam van de persoon of de instelling van wie het voorwerp is verworven. U kunt ook de reden noteren.<br /><br />Voorbeelden:<br />‘in 1992 overgebracht uit de Maartenskerk vanwege sluiting’<br />‘schenking P.D. Coppens in 1956’<br />‘in 1983 in bruikleen gekregen van de Congregatie van het Heilig Sacrament’" // jshint ignore:line
                        }
                    },
                    {
                        'name': 'remarks',
                        'template': 'textarea',
                        'label': gettext('Remarks'),
                        'options': {
                            'tooltip': "Hier kunt u aanvullende informatie over het voorwerp kwijt, die in geen enkel ander veld een geschikte plaats heeft."// jshint ignore:line
                        }
                    }
                ]
            };

            return {
                name: 'record',
                help: urlHelper.getBaseUrl() + '/download/registration',
                helpLabel: gettext('Object registration guidelines'),
                helpTitle: gettext('You can download the \'Guide for the drafting of a church inventory\'.'),
                label: gettext('General information'),
                helpList: [
                    {
                        link: urlHelper.getBaseUrl() + '/download/registratieformulier',
                        label: gettext('Paper registration'),
                        title: gettext('You can download a blank registration form here.')
                    },
                    {
                        link: urlHelper.getBaseUrl() + '/download/invulinstructie',
                        label: gettext('Instructions for filling in registration form'),
                        title: gettext('You can download an instruction for completing the registration form.')
                    }
                ],
                elements: [
                    generalFieldset,
                    detailFieldset,
                    dateFieldset,
                    locationsFieldset,
                    measurementsFieldset,
                    conditionFieldset,
                    additionalFieldset
                ],
                process: processStepOne
            };
        }

        function createFilesStep() {
            return {
                name: 'files',
                help: urlHelper.getBaseUrl() + '/download/media',
                helpLabel: gettext('Media Upload Guidelines'),
                helpTitle: gettext('Here is the document "Tips for shooting in churches" for download.'),
                label: gettext('Media'),
                prepare: prepareStepTwo,
                fileCount: 0
            };
        }

        function createEvaluationStep() {
            var evaluationStep = {
                name: 'evaluation',
                cssClass: 'valuation-form',
                help: urlHelper.getBaseUrl() + '/download/evaluation',
                helpLabel: gettext('Evaluation Procedure Guidelines'),
                helpTitle: gettext('Here is the document "Guide for appreciating and reallocating religious objects" for download.'), // jshint ignore:line
                label: gettext('Evaluation'),
                elements: [],
                process: processStepThree,
                clear: function () {
                    vm.wizard.cursor.data = {};
                    angular.forEach(
                        vm.wizard.cursor.elements[0].elements,
                        function (el) {
                            el.satisfied = false;
                        }
                    );
                }
            };

            var evaluation = new Evaluation();


            evaluation.rawRequest(
                'evaluation',
                'get',
                {},
                {}
            ).then(
                function (response) {
                    var elements = [];
                    var template = 'evaluation/expert';
                    if (!vm.user.isExpert() && !vm.user.isAdmin()) {
                        template = 'evaluation/regular';
                        vm.wizard.recapTooltip = 'U heeft een aantal vragen beantwoord die iets zeggen over de betekenis van het voorwerp. U heeft per vraag aangegeven hoe u de betekenis beoordeelt: \'laag\', \'midden\' of ‘\'hoog\', of dat u het niet wist. Uw scores zijn automatisch vertaald naar een eindscore. Deze eindscore is opgedeeld in de huidige betekenis en de historische betekenis.' // jshint ignore:line
                    } else {
                        vm.wizard.recapTooltip = 'Hier vindt u de eindscore van de zojuist ingevulde waardebepaling. De eindscore wordt automatisch berekend.<br /><br />De eerste twee vragen, over de historische en artistieke waarde, geven het voorwerp zijn primaire betekenis. In de eindscore komt dit tot uiting in de score: \'laag\', \'medium\' of \'hoog\'.<br /><br /> De volgende drie vragen gaan over vergelijkende criteria: zeldzaamheid, fysieke staat en ensemblewaarde. Deze criteria kunnen de primaire betekenis van een voorwerp beïnvloeden, maar nooit wezenlijk veranderen.<br /><br /> De beïnvloeding van de vergelijkende criteria op de primaire betekenis van een voorwerpen laten we zien met een ‘+’ of een ‘–’ achter de score. Een voorbeeld: vanwege de historische en artistieke waarde scoort een voorwerp ‘hoog’ (primaire betekenis). Doordat het niet zeldzaam blijkt te zijn en ook niet hoog scoort op fysieke staat en ensemblewaarde, wordt de primaire betekenis enigszins beïnvloed door de vergelijkende criteria. De eindscore is dan: ‘hoog–’.<br /><br /> Beïnvloeden de vergelijkende criteria de primaire betekenis niet? Dan blijft een ‘+’ of ‘–’ vanzelfsprekend achterwege.';  // jshint ignore:line
                    }

                    if (typeof response.data.questions !== 'undefined') {
                        angular.forEach(
                            response.data.questions,
                            function (question, index) {
                                var element = {
                                    'name': question['uuid'],
                                    'template': 'radiolist',
                                    'label': question['text'],
                                    'satisfied': false,
                                    'options' : {
                                        'choices': []
                                    }
                                };

                                if (question['tooltip'] !== '' && question['tooltip'] !== null) {
                                    element['options']['tooltip'] = $sce
                                        .trustAsHtml(
                                            question['tooltip'].replace(/(?:\r\n|\r|\n)/g, '<br />')
                                        );
                                }

                                if (index === 6 && question.answers.length === 4) {
                                    element['options']['tooltip0']= 'Ensemble bepaald door de samenhang in continuïteit van bezit en gebruik'; // jshint ignore:line
                                    element['options']['tooltip1'] = 'Ensemble bepaald door de samenhang van samenstelling of vervaardiging in één periode, maar niet volgens een integraal architectonisch concept'; // jshint ignore:line
                                    element['options']['tooltip2'] = 'Ensemble bepaald door de samenhang van een integraal architectonisch concept, een gesamtkunstwerk'; // jshint ignore:line
                                    element['options']['tooltip3'] = 'Ensemble bepaald door de samenhang van een van de vorige ensemblevormen. De onderdelen van dit ensemble bevinden zich echter niet langer op de oorspronkelijke locatie.';  // jshint ignore:line
                                    question['answers'] = question.answers.reverse();
                                }
                                angular.forEach(
                                    question['answers'],
                                    function (answer) {
                                        var isSatisfied = false;
                                        if (answer['text'] === 'slecht' ||
                                            (answer['weight'] > 10 &&
                                             answer['text'] !== 'goed' && answer['weight'] !== 150)) {
                                            isSatisfied = true;
                                        }
                                        element['options']['choices'].push(
                                            {
                                                'value': answer['weight'],
                                                'label': answer['text'],
                                                'satisfied': isSatisfied
                                            }
                                        );
                                    }
                                );
                                elements.push(element);
                            }
                        );

                        if (typeof elements[7] !== 'undefined') {
                            elements[7].options['vertical'] = true;
                        }

                        vm.wizard.getStepByMagic(
                            function (result, current) {
                                return current.order === 3;
                            }
                        ).setElements(
                            [
                                {
                                    'template': template,
                                    'elements': elements
                                }
                            ]
                        );
                    }
                }
            );
            return evaluationStep;
        }

        function createRecapStep() {
            return {
                label: gettext('Recap'),
                notEmpty: function (container, value) {
                    var empty = false;
                    if (typeof container[value] === 'undefined') {
                        empty = true;
                    } else {
                        if (container[value] === null || container[value] === '') {
                            empty = true;
                        }
                        if (_.isArray(container[value]) && container[value].length === 0) {
                            empty = true;
                        }
                    }
                    return !empty;
                },
                prepare: prepareStepFour,
            };
        }
    }
})(window.angular, window._);
