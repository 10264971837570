(function (angular, _) {
    'use strict';

    angular.module('McoCollectionPortal.user').service('User', User);

    /* @ngInject */
    function User ($window, session, base64,  ngDialog, messageService, 
        resourceBuilder, CONFIG) {
        var persist = this;

        return resourceBuilder.$new(
            'user',
            {id: '@_id'},
            {},
            {
                'login': userLogin,
                'logout': userLogout,
                'oauth': userOAuth,
                'profile': userProfile,
                'identity': userIdentity,
                'fromToken': userToken,
                'fromObject': userObject,
                'isLogged': userIsLogged,
                'isExpert': userIsExpert,
                'isAdmin': userIsAdmin,
                'isReadOnly': userIsReadOnly,
                'isReadOnlyAll': userIsReadAll,
                'getToken': getToken,
                'setToken': setToken,
                'setContext': setContext,
                'getContext': getContext,
                'getInstitutions': getInstitutions
            }
        );

        /**
         * Perform a login request, return deferred.
         * @return {String}
         **/
        function getToken() {
            /*jshint validthis:true */
            return persist.token;
        }

        /**
         * @param {String};
         * @return {Object}
         **/
        function setToken(token) {
            /*jshint validthis:true */

            persist.token = token;
            return this;
        }

        /**
         * @param {Object};
         * @return {Object}
         **/
        function setContext(context, quiet) {
            /*jshint validthis:true */
            persist.context = context;
            if (typeof quiet === 'undefined' || !quiet) {
                if (session.$isset('dialogReference')) {
                    ngDialog.close(session.$get('dialogReference'));
                }

                messageService.$raise('context_switch_success');
                $window.sessionStorage.setItem('mco_context' + this.id, JSON.stringify(persist.context));
            }
            return this;
        }

        /**
         * Get current user institution.
         * @return {Object}
         **/
        function getContext() {
            /*jshint validthis:true */
            return typeof persist.context !== 'undefined' ? persist.context : null;
        }

        /**
         * Perform a login request, return deferred.
         * @param {Object} Login data uname/pwd
         **/
        function userLogin(loginData) {
            /*jshint validthis:true */
            return this.rawRequest(
                'login',
                'POST',
                {},
                loginData
            );
        }

        /**
         * Save a profile request.
         * @param {Object} Login data uname/pwd
         **/
        function userProfile(userData) {
            /*jshint validthis:true */
            return this.rawRequest(
                'user',
                'PUT',
                {},
                userData
            );
        }

        /**
         * Authenticate via OAuth.
         **/
        function userOAuth() {
            $window.location = CONFIG.api.url + '/oauth';
        }

        /**
         * Load current authenticated user.
         * @return {Object}
         **/
        function userIdentity() {
            /*jshint validthis:true */
            var user = session.$get('user');
            if (!user) {
                if ($window.sessionStorage.getItem('mco_jwt')) {
                    this.fromToken($window.sessionStorage.getItem('mco_jwt'));
                }
                user = this;
            }

            var context = $window.sessionStorage.getItem('mco_context' + user.id);
            if (context) {
                context = JSON.parse(context);
                this.setContext(context, true);

            }

            return user;
        }

        /**
         * Load current authenticated user.
         * @param {String}
         **/
        function userToken(token) {
            /*jshint validthis:true */
            var self = this;
            var userData = token.split('.')[1];

            try {
                userData = JSON.parse(base64.urldecode(userData));
                if(typeof userData.id === 'object') {
                    userData.id = userData.id.$id;
                }
                this.fromObject(userData);
                this.setToken(token);
                session.$put('user', this);
            } catch (e) {
                messageService.$raise('user_load');
            }
            return false;
        }

        /**
         * Request user institutions
         **/
        function getInstitutions() {
            /*jshint validthis:true */
            return this.rawRequest(
                'user/institutions',
                'GET',
                {},
                {}
            );
        }

        /**
         * @param {Object}  User data
         **/
        function userObject(userData) {
            /*jshint validthis:true */
            var self = this;
            angular.forEach(
                userData,
                function (property, name) {
                    self[name] = property;
                }
            );
            return this;
        }

        //The following functions server to identify user's access
        //to interface sections. They are not real security, we take
        //care of this serverside.

        /**
         * @return {Boolean}
         **/
        function userIsAdmin() {
            /*jshint validthis:true */
            return this.role === 'Administrator';
        }
        
        /**
         * @return {Boolean}
         **/
        function userIsReadOnly() {
            /*jshint validthis:true */
            return this.role === 'Read only';
        }

        /**
         * @return {Boolean}
         **/
        function userIsReadAll() {
            /*jshint validthis:true */
            return this.role === 'Read only all';
        }

        /**
         * @return {Boolean}
         **/
        function userIsExpert() {
            /*jshint validthis:true */
            return this.role === 'Expert';
        }

        /**
         * @return {Boolean}
         **/
        function userIsLogged() {
            /*jshint validthis:true */
            return typeof this.role !== 'undefined' && this.role !== null;
        }

        //Clean up logged in user data.
        function userLogout() {
            /*jshint validthis:true */
            $window.sessionStorage.removeItem('mco_jwt');
            $window.sessionStorage.removeItem('mco_context' + this.id);
            session.$purge('user');
            session.$purge('mco_jwt');
            persist.context = null;
            messageService.$raise('user_sign_out', 'user_sign_out');
            return true;
        }       
    }
})(window.angular, window._);
