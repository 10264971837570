(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.user').controller('User.LogoutCtrl', LogoutController);

    /* @ngInject */
    function LogoutController ($state, User) {
        var vm = this;
        var user = new User();
        vm.user = user.identity();
        if (vm.user !== null) {
            vm.user.logout();
        }
        $state.go('mco.home');
    }
})(window.angular);
