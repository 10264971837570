(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.announcement').controller('Announcement.GridCtrl', GridController);

    /* @ngInject */
    function GridController ($state, gettext, ngDialog, session,
                             gridBuilder, Announcement, messageService) {
        var vm = this;

        vm.dataGrid = gridBuilder.$new(
            {},
            Announcement,
            {
                title: gettext('Title'),
                created: gettext('Date'),
                actions: gettext('Actions')
            }
        );
    }
})(window.angular);
