(function (angular, _) {
    'use strict';

    angular.module('McoCollectionPortal.memorix').filter('notHiddenFilter', NotHiddenFilter);

    /* @ngInject */
    function NotHiddenFilter() {
        return function (selection) {
            return _.filter(
                selection,
                function (item) {
                    return _.isUndefined(item.hidden) || (item.hidden !== true);
                }
            );
        };
    }

})(window.angular, window._);
