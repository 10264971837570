(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.request').controller('Request.ApproveCtrl', ApproveController);


    /* @ngInject */
    function ApproveController ($state, $scope, gettext, ngDialog, AutocompleteHelper, $filter,
                                formBuilder, Request, messageService, urlHelper, session, User) {
        var vm = this;

        vm.record = {};
        vm.assignUser = assignUser;

        vm.$form = formBuilder.$new(
            Request,
            [
                'subject',
                'body',
                'reviewer',                
                'institutions'
            ],
            {
                'general': messageService.$get('request_valid_error')
            }
        );

        vm.institutionAutocomplete = AutocompleteHelper.$new(vm);
        vm.institutionAutocomplete['label'] = messageService.$get('autocomplete_inst_label');
        vm.institutionAutocomplete['url'] = urlHelper.getAutocompleteUrl('reginstitutions');

        vm.userAutocomplete = AutocompleteHelper.$new(vm);
        vm.userAutocomplete['label'] = messageService.$get('autocomplete_user_label', true);
        vm.userAutocomplete['url'] = urlHelper.getAutocompleteUrl('userinstitutions');
        
        vm.adminAutocomplete = AutocompleteHelper.$new(vm);
        vm.adminAutocomplete['url'] = urlHelper.getAutocompleteUrl('admins');

        vm.denominationAutocomplete = AutocompleteHelper.$new(vm);
        vm.denominationAutocomplete['label'] = messageService.$get('autocomplete_denomination_label', true);
        vm.denominationAutocomplete['url'] = urlHelper.getAutocompleteUrl('denominations');
        vm.denominationAutocomplete['chain'] = urlHelper.getAutocompleteUrl('denominationinstitutions');

        vm.organizationAutocomplete = AutocompleteHelper.$new(vm);
        vm.organizationAutocomplete['label'] = messageService.$get('autocomplete_organization_label', true);
        vm.organizationAutocomplete['url'] = urlHelper.getAutocompleteUrl('umbrellaorganizations');
        vm.organizationAutocomplete['chain'] = urlHelper.getAutocompleteUrl('umbrellaorganizationinstitutions');

        activate();

        function assignUser() {
            vm.record.reviewer = vm.$form.fields.reviewer;
            var request = new Request(vm.record);
            if (session.$isset('dialogReference')) {
                ngDialog.close(session.$get('dialogReference'));
            }

            return request.rawRequest(
                'request/assign',
                'PUT',
                {},
                {
                    'id': request._id,
                    'reviewer': request.reviewer
                }
            ).then(
                function (response) {
                    if (response.data.msg !== 'reg_assign_fail') {
                        $state.transitionTo('mco.requests.extraregistrations', {}, {reload:true, notify: true, inherit: true});
                    }
                }
            );
        }

        function activate() {
            if ($scope.$parent.ngDialogData) {
                vm.$form.setData($scope.$parent.ngDialogData);
                vm.record = new Request($scope.$parent.ngDialogData);
                if(typeof vm.record.userId === 'object') {
                    vm.record.userId = vm.record.userId.$id;
                }
                User.get(
                    {id: vm.record.userId},
                    function (response) {
                        vm.record.user = response.data.data;
                        vm.$form.fields.institutions = $filter('filter')(
                            vm.$form.fields.institutions,
                            function (item) {
                                var unique = true;
                                angular.forEach(
                                    vm.record.user.institutions,
                                    function (institution) {
                                        if (institution.id === item.id) {
                                            unique = false;
                                        }
                                    }
                                );
                                return unique;
                            }
                        );
                    }
                );
            }
        }

    }
})(window.angular);
