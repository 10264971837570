(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.announcement').controller('Announcement.FormCtrl', FormController);


    /* @ngInject */
    function FormController ($scope, gettext, formBuilder, Announcement, messageService) {
        var vm = this;

        vm.record = {};

        vm.$form = formBuilder.$new(
            Announcement,
            [
                'title',
                'body',
                'published'
            ],
            {
                'general': messageService.$get('announcement_valid_error')
            }
        );

        activate();

        function activate() {
            if ($scope.$parent.ngDialogData) {
                vm.$form.setData($scope.$parent.ngDialogData);
                vm.record = $scope.$parent.ngDialogData;
            }
        }
    }
})(window.angular);
