(function (angular, toastr) {
    'use strict';

    angular.module('McoCollectionPortal.core').service('messageService', MessageService);

    /* @ngInject */
    function MessageService (gettext, gettextCatalog) {
        /*jslint maxlen: 250 */
        var self = this;

        self.defaultMsgText = gettext('Unknown error occured.');

        self.labels = {
            'labels': {
                'autocomplete_inst_label': gettext('Institution autocomplete...'),
                'autocomplete_user_label': gettext('User autocomplete...'),
                'autocomplete_organization_label': gettext('Umbrella organization autocomplete...'),
                'autocomplete_denomination_label': gettext('Denomination autocomplete...'),
            }
        };

        self.msgs = {
            'success': {
                'http_success': gettext('Operation completed successfully'),
                'login_success': gettextCatalog.getString(gettext('Successfully logged in.')),
                'reg_success': gettext('You will receive an e-mail shortly.'),
                'reg_success_title': gettext('Registration successful'),

                'export_query': gettext('Export queued successfully.'),
                'comment_registered': gettext('Comment successfully added.'),
                'reaction_registered': gettext('Correctie is verzonden.'),
                'user_sign_out': gettext('You have successfully logged out.'),
                'user_create_success': gettext('User successfully created.'),
                'user_edit_success': gettext('User successfully edited.'),
                'user_profile_success': gettext('Profile svaed successfully.'),
                'user_disabled_on': gettext('User successfully disabled.'),
                'user_disabled_off': gettext('User is enabled again.'),
                'user_password_success': gettext('User password reset sent.'),
                'announcement_create_success': gettext('Announcement successfully saved.'),
                'announcement_publish_on': gettext('Announcement successfully published.'),
                'announcement_publish_off': gettext('Announcement successfully pulled off.'),
                'announcement_delete_success': gettext('Announcement successfully removed.'),
                'announcement_edit_success': gettext('Announcement successfully modified.'),
                'correction_create_success': gettext('Your correction was successfully registered.'),
                'correction_reject_success': gettext('You have rejected this correction, the user has been notified.'),
                'correction_approve_success': gettext('The correction has been approved, the user has been notified.'),
                'correction_delete_success': gettext('Correction record successfully removed.'),
                'request_create_success': gettext('Your request for access was successfully registered.'),
                'request_reject_success': gettext('You have rejected this access request, the user has been notified.'),
                'request_approve_success': gettext('Access request approved, the user has been notified.'),
                'request_delete_success': gettext('Access request record successfully removed.'),
                'file_upload_success': gettext('File uploaded successfully.'),
                'file_delete_success': gettext('File removed successfully.'),
                'record_save_success': gettext('Record created successfully.'),
                'record_edit_success': gettext('Record saved successfully.'),
                'context_switch_success': gettext('Institution successfully set.'),
                'evaluation_success': gettext('Evaluation successfully saved.'),
                'reg_reject_success': gettext('You have rejected this registration, the user has been notified.'),
                'reg_delete_success': gettext('De registratie aanvraag is verwijderd.'),
                'reg_assign_success': gettext('De aanvraag is ter behandeling toegewezen.'),
                'user_delete_success': gettext('De gebruiker is verwijderd.'),
            },
            'error': {
                'export_query_failed': gettext('There was an error queueing the export. Please try again.'),
                'export_limit_size': gettext('It is not allowed to export more than 2000 records.'),
                'comment_register_error': gettext('There was a problem registering your comment. Please try again.'),

                'login_fail': gettext('Unable to identify, Please verify your account info.'),
                'user_load': gettext('Could not load a valid user profile.'),
                'unauthorized': gettext('You are not authorized to perform this action.'),
                'access_denied': gettext('You refused access to Memorix for the MCO Collection Portal. Login failed.'),
                'oauth_issue': gettext('There was a problem communicating with Memorix Maior. Please try again.'),
                'oauth_state': gettext('Invalid OAuth transaction detected. Please try again.'),
                'oauth_timeout': gettext('OAuth transaction timed out. Please try again.'),
                'oauth_token': gettext('OAuth token is invalid. Please try again.'),
                'oauth_user': gettext('Unable to fetch your Memorix Maior credentials.'),
                'oauth_new_user': gettext('Internal issue with account synchronization.'),
                'reg_fail': gettext('Registration failed. Please, try again.'),
                'user_profile_fail': gettext('Profile could not be saved, please try again.'),
                'user_edit_fail': gettext('User modification failed.'),
                'user_edit_forbidden': gettext('External Memorix users can not be modified.'),
                'user_create_fail': gettext('User creation failed.'),
                'user_create_fail_mail': gettext('E-mail address is not unique. User creation failed'),
                'user_disabled_fail': gettext('User status operation failed.'),
                'announcement_create_fail': gettext('There was an error registering the announcement.'),
                'announcement_publish_fail': gettext('Error trying to modify announcement status.'),
                'announcement_delete_fail': gettext('Error removing announcement. Please try again.'),
                'announcement_edit_fail': gettext('There was an error trying to modify the announcement.'),
                'correction_create_fail': gettext('There was a problem registering your correction.'),
                'correction_reject_fail': gettext('There was an issue rejecting the correction.'),
                'correction_approve_fail': gettext('Correction could not be approved.'),
                'correction_delete_fail': gettext('There was a problem trying to remove the correction record.'),
                'request_create_fail': gettext('There was a problem registering your access request.'),
                'request_reject_fail': gettext('There was an issue rejecting the request.'),
                'request_approve_fail': gettext('Access request could not be approved.'),
                'request_delete_fail': gettext('There was a problem trying to remove the access request record.'),
                'file_upload_fail': gettext('There was a problem adding your file.'),
                'file_delete_fail': gettext('The file could not be removed.'),
                'control_list_fail': gettext('Loading control list values failed.'),
                'record_save_fail': gettext('Error creating record. If the problem persists, please contact an administrator.'),
                'record_upload_fail': gettext('The record was created, but there was a problem uploading record files.'),
                'record_edit_forbidden': gettext('You are not authorized to edit this record.'),
                'record_edit_fail': gettext('There was a problem saving this record. Please try again'),
                'record_edit_protected': gettext('You are not allowed to edit this record.'),
                'institution_load_fail': gettext('There was a problem loading the selected institution.'),
                'evaluation_fail': gettext('There was a problem saving your evaluation. Please try again.'),
                'evaluation_protected': gettext('This record is part of the core collection and you are not allowed to modify it.'),
                'reg_reject_fail': gettext('There was an issue rejecting the registration.')
            },
            'form': {
                'reg_valid_error': gettext('Please, correct the errors and resubmit the registration.'),
                'login_valid_error': gettext('Please, fill in the login form correctly.'),
                'profile_valid_error': gettext('Please, correct the errors so we can save your profile information.'),
                'announcement_valid_error': gettext('Please, correct the errors to successfully save the announcement.'),
                'correction_valid_error': gettext('The correction form you are submitting is not filled in correctly. Please, correct the errors and resubmit.')
            }
        };

        self.$get = getByKey;
        self.$register = registerKey;
        self.$raise = raiseByKey;

        function getByKey(key, isLabel) {
            var msg = null;
            angular.forEach(
                (typeof isLabel !== 'undefined' && isLabel ? self.labels : self.msgs),
                function (coll, index) {
                    if (typeof coll[key] !== 'undefined') {
                        msg = coll[key];
                        return false;
                    }
                }
            );
            msg = gettextCatalog.getString(msg);
            return msg;
        }

        //Type is intentionally second, do not default it do not skip it.
        function registerKey(key, type, msg)
        {
            if (typeof self.msgs[type] === 'undefined') {
                self.msgs[type] = {};
            }
            self.msgs[type][key] = msg;
        }


        function raiseByKey(key, defaultMsg) {
            var msg = null;
            angular.forEach(
                self.msgs,
                function (coll, index) {
                    if (typeof coll[key] !== 'undefined') {
                        msg = coll[key];
                        toastr[index](gettextCatalog.getString(msg));
                        return false;
                    }
                }
            );

            if ((msg === null) && (typeof defaultMsg !== 'undefined')) {
                toastr.error(gettextCatalog.getString(defaultMsg));
            }
            return msg;
        }
    }
})(window.angular, window.toastr);
