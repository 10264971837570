(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.core').service('session', SessionService);

    /* @ngInject */
    function SessionService ($window) {
        var self = this;

        self.storage = {};

        self.$get = sessionGet;
        self.$put = sessionPut;
        self.$isset = sessionIsset;
        self.$purge = sessionPurge;

        function sessionGet(key) {
            return self.$isset(key) ? self.storage[key] : null;
        }

        function sessionPut(key, value) {
            self.storage[key] = value;
        }

        function sessionIsset(key) {
            return typeof self.storage[key] !== 'undefined';
        }

        function sessionPurge(key) {
            if (self.$isset(key)) {
               delete self.storage[key];
            }
        }

    }
})(window.angular);
