(function (angular) {
    'use strict';

    //All this does is useless dispatch
    angular.module('McoCollectionPortal.dashboard').controller('Dashboard.ButtonsCtrl', ButtonsController);

    /* @ngInject */
    function ButtonsController ($state, gettext, User) {
        /* jshint validthis: true */
        var vm = this;
        vm.user = new User();
        vm.dispatch = function (button) {
            var user = vm.user.identity();
            if (button.isReadOnly && (user.isReadOnly() || user.isReadOnlyAll())) {
                return false;
            }

            if (typeof button.state !== 'undefined') {
                $state.go(button.state);
            } else if (typeof button.href !== 'undefined') {
                window.location.href = button.href;
            } else if (typeof button.custom === 'function') {
                button.custom();
            }
        };

        vm.list = [
            {
                'state': 'mco.objects',
                'icon': 'icon-edit',
                'class': 'valuation button',
                'name': gettext('Object registration'),
								'isReadOnly': true,
								'withoutInstitution': false
            },
            {
                'custom': function () {
                    var base = '//' + window.location.hostname + '/mediabank/';
                    var full = base + '?fq[search_s_institution_acl][]=' + encodeURI(vm.user.getContext().acl);
                    window.location.href = full;
                },
                'icon': 'icon-photo',
                'class': 'report button',
								'name': gettext('View collection'),
								'withoutInstitution': false
            },
            {
                'state': 'mco.users',
                'icon': 'icon-users',
                'class': 'users button',
                'name': gettext('Users'),
                'access': {
                    isAdmin: true
								},
								'withoutInstitution': true
            },
            {
                'state': 'mco.requests',
                'icon': 'icon-inbox',
                'class': 'requests button',
                'name': gettext('Requests'),
                'access': {
                    isAdmin: true
								},
								'withoutInstitution': true
            },
            {
                'state': 'mco.announcements',
                'icon': 'icon-new',
                'class': 'messages button',
                'name': gettext('Announcements'),
                'access': {
                    isAdmin: true
								},
								'withoutInstitution': true
            }
        ];

        activate();

        function activate() {
            vm.user = vm.user.identity();
        }
    }
})(window.angular);
