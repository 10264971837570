(function (angular) {
    'use strict';

    //All this does is useless dispatch
    angular.module('McoCollectionPortal.dashboard').controller('Dashboard.SearchCtrl', SearchController);

    /* @ngInject */
    function SearchController ($state, gettext, messageService, User) {
        /* jshint validthis: true */
        var vm = this;
        vm.user = null;
        vm.run = searchObjects;
        vm.placeholder = gettext('Search in');
        vm.query = '';
        activate();

        function activate() {
            vm.user = new User();
            vm.user = vm.user.identity();
        }

        function searchObjects () {
            var base = '//' + window.location.hostname + '/mediabank/';
            var full = base + '?q=' + vm.query+ '&fq[search_s_institution_acl][]=' + encodeURI(vm.user.getContext().acl); // jshint ignore:line
            window.location.href = full;
        }
    }
})(window.angular);
