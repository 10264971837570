(function (angular, _) {
    'use strict';

    angular.module('McoCollectionPortal.memorix')
        .directive('mmxElement', MmxElement);

    /* @ngInject */
    function MmxElement() {
        var directive = {
            restrict: 'E',
            replace: true,
            scope: {
                content: '=content',
                owner: '=owner'
            },
            template: '<ng-include src="getElementTemplate()">',
            link: link
        };

        return directive;

        /**
         * @param {Object} scope
         * @param {DOMElement}
         * @param {Object} attributes
         **/
        function link(scope, element, attr) {
            scope.addRecord = function () {
                var defaultRecord = _.clone(scope.getOption('defaultRecord'));
                //Defensive copy to lvl 1.
                _.each(
                    defaultRecord,
                    function (item, index) {
                        if (_.isObject(item)) {
                            defaultRecord[index] = _.clone(item);
                        }
                    }
                );

                if (!_.isArray(scope.owner.data[scope.content.name])) {
                    scope.owner.data[scope.content.name] = [];
                }
                scope.owner.data[scope.content.name].push(defaultRecord);
            };

            scope.removeRecord = function (index) {
                scope.owner.data[scope.content.name].splice(index, 1);
            };

            scope.getOption = function (key) {
                var option = null;
                if (typeof scope.content.options !== 'undefined' && typeof scope.content.options[key] !== 'undefined') {
                    option =  scope.content.options[key];
                }
                return option;
            };

            scope.getElementTemplate = function () {
                var templatePath = '';
                if (!_.isUndefined(scope.content) && !_.isUndefined(scope.content.template)) {
                    templatePath = 'memorix/views/elements/' + scope.content.template + '.html';
                }
                return templatePath;
            };

            scope.thesaurusAdd = (function () {
                var thesaurus = scope.content;
                var thesaurusOwner = scope.owner;
                return function (subject) {
                    var field = thesaurus.name;
                    if (typeof subject === 'undefined') {
                        return; //Fixes an angucomplete bug
                    }
                    var add = true;
                    if (typeof thesaurusOwner.data[field] === 'undefined' || thesaurusOwner.data[field].length === 0) {
                        thesaurusOwner.data[field] = [];
                    }
                    if (typeof subject.originalObject.uri !== 'undefined') {
                        angular.forEach(
                            thesaurusOwner.data[field],
                            function (item) {
                                if (item.uri === subject.originalObject.uri) {
                                    add = false;
                                }
                            }
                        );

                        if (add) {
                            thesaurusOwner.data[field].push(subject.originalObject);
                        }
                    }
                };
            })();

            scope.thesaurusRemove = (function (thesaurus, thesaurusOwner) {
                return function (field, subject) {
                    /*jshint validthis:true */
                    if (typeof thesaurusOwner.data[field] === 'undefined') {
                        thesaurusOwner.data[field] = [];
                    }

                    thesaurusOwner.data[field] = _.filter(
                        thesaurusOwner.data[field],
                        function (item) {
                            return item.uri !== subject.uri;
                        }
                    );
                };
            })(scope.content, scope.owner);


            scope.radiolistSatisfied = (function (radiolist, radiolistOwner) {
                return function (satisfied) {
                    radiolist.satisfied = satisfied;
                };
            })(scope.content, scope.owner);

            scope.setAutocomplete = function (choice) {
                if (typeof choice === 'object' && typeof choice.originalObject !== 'undefined') {
                    scope.owner.data[scope.content.name] = choice.originalObject;
                    scope.content.defaultValue = choice.originalObject.title;
                }
            };
        }
    }
})(window.angular, window._);
