(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.memorix').service('Monument', Monument);

    /* @ngInject */
    function Monument (resourceBuilder) {
        return resourceBuilder.$new(
            'monument',
            {id: '@_id'},
            {},
            {}
        );
    }
})(window.angular);
