(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.request').service('Request', Request);

    /* @ngInject */
    function Request ($state, $window, ngDialog, session, resourceBuilder, messageService, $filter) {
        return resourceBuilder.$new(
            'request',
            {id: '@_id'},
            {},
            {
                'approve': approveRequest,
                'setStatus': statusUpdate,
                'removeRecord': removeRecord
            }
        );

        function removeRecord() {
            /*jshint validthis:true */
            var confirm = $window.confirm('Weet u zeker dat u deze extra toegangsaanvraag wilt verwijderen?');
            if(confirm === true) {
              if (session.$isset('dialogReference')) {
                ngDialog.close(session.$get('dialogReference'));
              }
  
              return this.rawRequest(
                  'request/' + this._id,
                  'DELETE',
                  {},
                  {}
              ).then(
                  function (response) {
                    $state.transitionTo('mco.requests.extraregistrations', {}, {reload:true, notify: true, inherit: true});
                  }
              );
            }
        }
        
        function statusUpdate(status, form) {
            /*jshint validthis:true */
            var self = this;
            if (session.$isset('dialogReference')) {
                ngDialog.close(session.$get('dialogReference'));
            }

            var newInstitutions = $filter('filter')(
                form.fields.institutions,
                function (item) {
                    var unique = true;
                    angular.forEach(
                        self.user.institutions,
                        function (institution) {
                            if (institution.id === item.id) {
                                unique = false;
                            }
                        }
                    );
                    return unique;
                }
            );

            return this.rawRequest(
                'request/status',
                'PUT',
                {},
                {
                    'id': this._id,
                    'status': status,
                    'institutions': newInstitutions
                }
            ).then(
                function (response) {
                    $state.transitionTo('mco.requests', {}, {reload:true, notify: true, inherit: true});
                }
            );
        }

        function approveRequest(form) {
            /*jshint validthis:true */
            var self = this;
            if (session.$isset('dialogReference')) {
                ngDialog.close(session.$get('dialogReference'));
            }
            var newInstitutions = $filter('filter')(
                form.fields.institutions,
                function (item) {
                    var unique = true;
                    angular.forEach(
                        self.user.institutions,
                        function (institution) {
                            if (institution.id === item.id) {
                                unique = false;
                            }
                        }
                    );
                    return unique;
                }
            );

            return this.rawRequest(
                'request/approve',
                'PUT',
                {},
                {
                    'id': this._id,
                    'institutions': newInstitutions
                }
            ).then(
                function (response) {
                    $state.transitionTo('mco.requests', {}, {reload:true, notify: true, inherit: true});
                }
            );
        }
    }
})(window.angular);
