(function (angular, _) {
    'use strict';

    angular.module('McoCollectionPortal.memorix')
        .factory('MmxWizard', MmxWizard);

    /* @ngInject */
    function MmxWizard($state, $stateParams, dialogService, MmxWizardStep) {
        var mmxWizard = {
            $new: createWizard
        };

        /**
         * @param {String} base state
         * @param {Array} sequence of steps
         **/
        var Wizard = function (wizardRoot, wizardSteps, wizardOptions) {
            var self = this;
            self.id = new Date().getTime().toString() + '-' + Math.random().toString().substr(2);
            self.root = wizardRoot;
            self.steps = [];
            self.options = wizardOptions;
            self.cursor = null;
            self.loading = false;
            self.submitted = false;
            self.failed = false;

            angular.forEach(
                wizardSteps,
                function (definition, index) {
                    definition.order = (index + 1);
                    definition.target = self.root + '.step' + definition.order;
                    self.steps.push(MmxWizardStep.$new(definition, self));
                }
            );
        };

        Wizard.prototype = {
            'constructor': Wizard,
            'setActiveStep': setWizardActiveStep,
            'getActiveStep': getWizardActiveStep,
            'getStepByMagic': getWizardStepByMagic,
            'hasNextStep': hasWizardNextStep,
            'hasPreviousStep': hasWizardPreviousStep,
            'getNextStep': getWizardNextStep,
            'getPreviousStep': getWizardPreviousStep,
            'gotoPreviousStep': gotoWizardPreviousStep,
            'gotoNextStep': gotoWizardNextStep,
            'getOption': getOption,
            'recapElement': recapWizardElement,
            'submit': wizardSubmit
        };

        return mmxWizard;

        /**
         * @return {This}
         **/
        function gotoWizardPreviousStep() {
            /*jshint validthis:true */
            this.setActiveStep(this.getPreviousStep());
            return this;
        }

        function gotoWizardNextStep() {
            /*jshint validthis:true */
            var nextStep = null;

            if (typeof this.cursor.validate !== 'function' || this.cursor.validate.call(this)) {
                if (typeof this.cursor.process === 'function') {
                    if (this.cursor.process.call(this) === false) {
                        return ;
                    }
                }
                nextStep = this.getNextStep();
                if (nextStep === null) {
                    this.complete();
                } else {
                    this.setActiveStep(nextStep);
                }
            }
        }

        /**
         * @return {Boolean}
         **/
        function hasWizardPreviousStep() {
            /*jshint validthis:true */
            return this.getPreviousStep() !== null;
        }

        /**
         * @return {Boolean}
         **/
        function hasWizardNextStep() {
            /*jshint validthis:true */
            return this.getNextStep() !== null;
        }

        /**
         * @return {Object}
         **/
        function getWizardNextStep() {
            /*jshint validthis:true */
            return this.getStepByMagic(
                function (result, current) {
                    return result === null && !current.skip && (!this.cursor || (current.order > this.cursor.order));
                }
            );
        }

        /**
         * @return {Object}
         **/
        function getWizardPreviousStep() {
            /*jshint validthis:true */
            return this.getStepByMagic(
                function (result, current) {
                    return result === null && !current.skip && (this.cursor && (current.order < this.cursor.order));
                },
                this.steps.slice().reverse()
            );
        }

        /**
         * @param {Function}
         **/
        function getWizardStepByMagic(fn, selection) {
            /*jshint validthis:true */
            var self = this;
            if (typeof selection === 'undefined') {
                selection = this.steps;
            }
            return _.reduce(
                selection,
                function (result, current) {
                    if (fn.call(self, result, current)) {
                        result = current;
                    }
                    return result;
                },
                null
            );
        }

        /**
         * @return {Object}
         **/
        function getWizardActiveStep() {
            /*jshint validthis:true */
            return this.getStepByMagic(
                function (result, current) {
                    return result === null && current.getActive();
                }
            );
        }

        /**
         * @param Mixed
         * @return {This}
         **/
        function setWizardActiveStep(step) {
            /*jshint validthis:true */
            var self = this;
            if (typeof step === 'undefined' || step === null) {
                this.cursor = this.getNextStep();
            } else if (typeof step === 'object') {
                this.cursor = step;
            } else {
                this.cursor = this.getStepByMagic(
                    function (result, current) {
                        return _.isNull(result) && current.order === step;
                    }
                );
            } 
            
            angular.forEach(
                this.steps,
                function (s) {
                    s.setActive(false);
                }
            );

            if (this.cursor !== null) {
                this.cursor.setActive(true);
                $state.transitionTo(this.cursor.getTarget(), $stateParams).then(
                    function (target) {
                        if (typeof self.cursor.prepare === 'function')  {
                            self.cursor.prepare.call(self);
                        }
                    }
                );
            }
            return this;
        }

        /**
         * Get an option
         * @return {Mixed}
         **/
        function getOption (key) {
            /*jshint validthis:true */
            return this.options && typeof this.options[key] !== 'undefined' ?
                this.options[key] : 'Option not found';
        }

        /**
         *
         **/
        function wizardSubmit() {
            /*jshint validthis:true */
            var self = this;
            var wizardData = {};
            angular.forEach(
                this.steps,
                function (step) {
                    if (!step.skip) {
                        if (step.name !== null)  {
                            wizardData[step.name] = step.data;
                        }
                    }
                }
            );

            var evaluationData = _.omit(
                wizardData['evaluation'],
                'questions'
            );

            evaluationData['questions'] = wizardData['evaluation']['questions'][0]['elements'];
            wizardData['evaluation'] = evaluationData;

            var wizardRecord = new (this.getOption('model'))(wizardData);
                        
            this.loading = true;

            wizardRecord.$save(
                function (response) {
                    if (typeof response.data.status !== 'undefined' && response.data.status === 'ok') {
                        self.submitted = true;
                        if (!_.isEmpty(self.getOption('successMsg'))) {
                            dialogService.$dialog(
                                self.getOption('successMsg'), 
                                self.getOption('successTitle'), 
                                'icon-ok',
                                self.getOption('successCb')
                            );
                        }
                    } else {
                        self.failed= true;
                    }
                    self.loading = false;
                }
            );
        }

        function recapWizardElement(elementValue) {
            var value = elementValue;
            if (_.isArray(value)) {
                value = _.reduce(
                    value,
                    function (result, item) {
                        var stringified = '';
                        if (typeof item.label !== 'undefined') {
                            stringified = item.label;
                        } else if (typeof item.creator !== 'undefined') {
                            stringified = [];
                            _.each(
                                ['creator', 'role', 'place'],
                                function (key) {
                                    if (typeof item[key] !== 'undefined') {
                                        if (key === 'creator') {
                                            stringified.push(item[key].title);
                                        } else {
                                            stringified.push(item[key]);
                                        }
                                    }
                                }
                            );
                            stringified = stringified.join(' / ');
                        }
                        result.push(stringified);
                        return result;
                    },
                    []
                );
                value = value.join(', ');
            }

            return value;
        }

        /**
         * Create a simple form manager.
         * @param {Array} constructor
         **/
        function createWizard(wizardBase, wizardSteps, wizardOptions) {
            return new Wizard(wizardBase, wizardSteps, wizardOptions);
        }
    }
})(window.angular, window._);
