(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.correction', [])
        .config( routeConfig );

    /* @ngInject */
    function routeConfig($stateProvider) {
    }

})(window.angular);
