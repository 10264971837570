(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.core')
        .directive('matchInput', MatchInput);

    //This directive assumes using the formBuilder factory.

    /* @ngInject */
    function MatchInput(session) {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: link
        };

        return directive;

        function link(scope, ignore, attr, ngModelCtrl) {
            //Check password match at the start.
            //Empty passowrds are fine.
            ngModelCtrl.$parsers.unshift(
                function(inputValue) {
                    if (typeof scope[attr.matchInputContext].$form.fields[attr.matchInput] !== 'undefined' &&
                        inputValue === scope[attr.matchInputContext].$form.fields[attr.matchInput]) {
                        ngModelCtrl.$setValidity('matchInput', true);

                        //We are all good, keep going.
                        return inputValue;
                    } else {
                        ngModelCtrl.$setValidity('matchInput', false);
                        return undefined;
                    }
                }
            );
        }
    }
})(window.angular);
