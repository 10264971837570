(function (angular, _) {
    'use strict';

    angular.module('McoCollectionPortal.core').service('dialogService', DialogService);

    /* @ngInject */
    function DialogService ($rootScope, ngDialog, messageService) {
        var self = this;

        self.$dialog = showDialog;

        function showDialog(msg, title, icon, cb) {
            var scope = $rootScope.$new();
            var dialog = {};
            dialog.msg = messageService.$get(msg);
            dialog.title = messageService.$get(title);
            if (_.isEmpty(dialog.msg)) {
                dialog.msg = msg;
            }

            if (_.isEmpty(dialog.title)) {
                dialog.title = title;
            }
            dialog.icon = icon;

            scope.dialog = dialog;

            var d = ngDialog.open(
                {
                    className: 'ngdialog-theme-plain',
                    template: 'core/views/partials/dialog.html',
                    scope: scope
                }
            );

            if (_.isFunction(cb)) {
                d['closePromise'].then(
                    cb
                );
            }
        }
    }
})(window.angular, window._);
