(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.user').controller('User.TabsCtrl', TabsController);

    /* @ngInject */
    function TabsController ($state, gettext, Registration) {
        var vm = this;

        vm.list = [
            {active: false, label: gettext('Users'), target: 'mco.users'}
        ];

        angular.forEach(
            vm.list,
            function (tab) {
                if (tab.target === $state.current.name) {
                    tab.active = true;
                }
            }
        );

        vm.size = 'medium-';

        activate();

        function activate() {
            vm.size = vm.size + (12 / vm.list.length);
            Registration.query(
                {
                    'filter': {
                        'status': 'new'
                    }
                },
                function (response) {
                    vm.list[1].count = response.data.total;
                }
            );

        }
    }
})(window.angular);
