(function (angular, _) {
    'use strict';

    angular.module('McoCollectionPortal.memorix')
        .factory('Evaluation', Evaluation);

    /* @ngInject */
    function Evaluation (resourceBuilder) {
        return resourceBuilder.$new(
            'evaluation',
            {id: '@_id'},
            {},
            {}
        );
    }

})(window.angular, window._);
