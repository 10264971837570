(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.core').filter('accessFilter', AccessFilter);

    /* @ngInject */
    function AccessFilter(User) {
        return function (selection) {
            var user = new User();
            var allowedSelection = [];
            user = user.identity();
            if (user)  {
                angular.forEach(
                    selection,
                    function(descriptor) {
                        if (typeof descriptor.access !== 'undefined') {
                            angular.forEach(
                                descriptor.access,
                                function(expected, restriction) {
                                    if ((typeof user[restriction] === 'function') &&
                                       (user[restriction]() === expected)) {
                                        allowedSelection.push(descriptor);
                                    }
                                }
                            );
                        } else {
                            allowedSelection.push(descriptor);
                        }
                    }
                );
            }
            return allowedSelection;
        };
    }

})(window.angular);
