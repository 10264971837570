(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.user', [])
        .config( routeConfig );

    /* @ngInject */
    function routeConfig($stateProvider) {
        $stateProvider
            .state('mco.users', {
                url: '/users',
                views: {
                    '': {
                        templateUrl: 'user/views/index.html'
                    },
                    'userList@mco.users': {
                        templateUrl: 'user/views/partials/grid.html',
                        controller: 'User.GridCtrl',
                        controllerAs: 'grid'
                    }
                },
                access: {
                    isAdmin: true
                }
            });
    }
})(window.angular);
