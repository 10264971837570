(function (angular, $) {
    'use strict';

    angular.module('McoCollectionPortal.core')
        .factory('AutocompleteHelper', AutocompleteHelper);


    //Refactor this to general if needed as multiple autocomplete somewhere.
    /* @ngInject */
    function AutocompleteHelper($q, $http, $filter) {


        var autocomplete = {
            $new: createAutocomplete
        };

        return autocomplete;

        function createAutocomplete(context) {
            return {
                'process': processInstitution.bind(context),
                'institutions': institutions.bind(context),
                'remove': removeInstitution.bind(context),
                'addUser': addUser.bind(context),
                'userInstitutions': userInstitutions.bind(context),
                'denominationInstitutions': denominationInstitutions.bind(context),
                'organizationInstitutions': organizationInstitutions.bind(context)
            };
        }

        /**
         * @param {Object}
         **/
        function addUser(result) {
            /*jshint validthis:true */
            var self = this;
            if (typeof result !== 'undefined') {
                this.$form.fields.reviewer = result.title;
            }
        }

        /**
         * @param {Object}
         **/
        function processInstitution(result) {
            /*jshint validthis:true */
            var self = this;
            if (typeof result !== 'undefined' && typeof result.originalObject.id !== 'undefined') {
                if (typeof result.originalObject.institutions !== 'undefined') {
                    angular.forEach(
                        result.originalObject.institutions,
                        function (institution) {
                            addInstitution.call(self, institution);
                        }
                    );
                } else {
                    addInstitution.call(this, result.originalObject);
                }
            }
        }

        /**
         * @param {Object}
         **/
        function institutions(result) {
            /*jshint validthis:true */
            var self = this;
            if (typeof result !== 'undefined' && typeof result.originalObject.id !== 'undefined') {
                if (typeof result.originalObject.institutions !== 'undefined') {
                    angular.forEach(
                        result.originalObject.institutions,
                        function (institution) {
                            addInstitution.call(self, institution);
                        }
                    );
                    self.userInstitutionsDisabled = true;
                    self.denominationInstitutionsDisabled = true;
                    self.organizationInstitutions = true;

                } else {
                    addInstitution.call(this, result.originalObject);
                    self.userInstitutionsDisabled = true;
                    self.denominationInstitutionsDisabled = true;
                    self.organizationInstitutions = true;
                }
            }
        }

        /**
         * @param {Object}
         **/
        function userInstitutions(result) {
            /*jshint validthis:true */
            var self = this;
            if (typeof result !== 'undefined' && typeof result.originalObject.id !== 'undefined') {
                if (typeof result.originalObject.institutions !== 'undefined') {
                    angular.forEach(
                        result.originalObject.institutions,
                        function (institution) {
                            addInstitution.call(self, institution);
                        }
                    );
                    self.institutionsDisabled = true;
                    self.denominationInstitutionsDisabled = true;
                    self.organizationInstitutions = true;
                } else {
                    addInstitution.call(this, result.originalObject);
                }
            }
        }



        /**
         * @param {Object}
         **/
        function removeInstitution(institution) {
            /*jshint validthis:true */
            this.$form.fields.institutions = $filter('filter')(
                this.$form.fields.institutions,
                function (item) {
                    return institution.id !== item.id;
                }
            );
        }

        /**
         * @param {Object}
         **/
        function addInstitution(newInstitution) {
            /*jshint validthis:true */
            var add = true;
            if(this.$form.fields.institutions === null) {
                this.$form.fields.institutions = [];
            }
            angular.forEach(
                this.$form.fields.institutions,
                function(institution) {
                    if (institution.id === newInstitution.id) {
                        add = false;
                    }
                }
            );

            if (add) {
                this.$form.fields.institutions.push(newInstitution);
            }
        }

        /**
         * @param {Object}
         **/
        function denominationInstitutions(result) {
            /*jshint validthis:true */
            var self = this;

            if (typeof self.denominationAutocomplete.chain !== 'undefined' &&
                typeof result !== 'undefined' &&
                typeof result.originalObject.title !== 'undefined'
            ) {
                var url = self.denominationAutocomplete.chain +
                    encodeURIComponent(result.originalObject.title);
                var params = {};
                var httpCanceller = $q.defer();
                params.timeout = httpCanceller.promise;
                $http.get(url, params)
                    .then(function(response) {
                        if (typeof response.data !== 'undefined') {
                            angular.forEach(
                                response.data,
                                function (institution) {
                                    addInstitution.call(self, institution);
                                }
                            );
                            self.institutionsDisabled = true;
                            self.userInstitutionsDisabled = true;
                            self.organizationInstitutions = true;
                        }
                    }, function(response) {
                    });
            }
        }

        /**
         * @param {Object}
         **/
        function organizationInstitutions(result) {
            /*jshint validthis:true */
            var self = this;
            if (typeof self.organizationAutocomplete.chain !== 'undefined' &&
                typeof result !== 'undefined' &&
                typeof result.originalObject.title !== 'undefined'
            ) {
                var url = self.organizationAutocomplete.chain +
                    encodeURIComponent(result.originalObject.title);
                var params = {};
                var httpCanceller = $q.defer();
                params.timeout = httpCanceller.promise;
                $http.get(url, params)
                    .then(function(response) {
                        if (typeof response.data !== 'undefined') {
                            angular.forEach(
                                response.data,
                                function (institution) {
                                    addInstitution.call(self, institution);
                                }
                            );
                            self.institutionsDisabled = true;
                            self.userInstitutionsDisabled = true;
                            self.denominationInstitutionsDisabled = true;
                        }
                    }, function(response) {
                    });
            }
        }
    }
})(window.angular);
