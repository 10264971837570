(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.registration').controller('Registration.RegisterCtrl', RegisterController);

    /* @ngInject */
    function RegisterController (session, urlHelper, formBuilder, Registration,
                              AutocompleteHelper, ngDialog, dialogService, messageService) {
        /* jshint validthis: true */
        var vm = this;
        vm.record = {};
        vm.startRegistration = registrationStart;

        vm.$form = formBuilder.$new(
            Registration,
            [
                'firstName',
                'lastName',
                'institutions',
                'email',
                'reason',
                'access'
            ],
            {
                'general': messageService.$get('reg_valid_error')
            },
            {
                //Disable this for a bit, not sure its necessary.
                /////////////////////////////////////////////////////////
                // 'postPersist': function(response) {                 //
                //     var msg = 'reg_error';                          //
                //     var title = 'reg_error_title';                  //
                //     var icon = 'icon-warning';                      //
                //                                                     //
                //     if (typeof response.data.msg !== 'undefined') { //
                //         msg = response.data.msg;                    //
                //         title = 'reg_success_title';                //
                //         icon = 'icon-ok';                           //
                //     }                                               //
                //                                                     //
                //     dialogService.$dialog(msg, title, icon);        //
                // }                                                   //
                /////////////////////////////////////////////////////////
            }
        );

        vm.institutionAutocomplete = AutocompleteHelper.$new(vm);
        vm.institutionAutocomplete['label'] = messageService.$get('autocomplete_inst_label');
        vm.institutionAutocomplete['url'] = urlHelper.getAutocompleteUrl('reginstitutions');

        function registrationStart() {
            session.$put(
                'dialogReference',
                ngDialog.open(
                    {
                        className: 'ngdialog-theme-plain',
                        template: 'registration/views/forms/register.html'
                    }
                )
            );
        }
    }
})(window.angular);
