(function (angular, _) {
    'use strict';

    angular.module('McoCollectionPortal.memorix').filter('dateFilter', DateFilter);

    /* @ngInject */
    function DateFilter() {
        return function (selection) {
            return _.map(
                selection,
                function (record) {
                    if (_.isFunction(record['earliest'].toISOString)) {
                        record['earliest'] = record['earliest'].toISOString();
                        record['earliest'] = record['earliest'].slice(0, record['earliest'].indexOf('T'));
                    }

                    if (_.isFunction(record['latest'].toISOString)) {
                        record['latest'] = record['latest'].toISOString();
                        record['latest'] = record['latest'].slice(0, record['latest'].indexOf('T'));
                    }

                    return record;
                }
            );
        };
    }

})(window.angular, window._);
