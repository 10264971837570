(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.core').controller('Core.HeaderCtrl', HeaderController);


    /* @ngInject */
    function HeaderController ($window, $state, ngDialog, session, formBuilder, messageService, User) {
        var vm = this;
        vm.user = new User();

        vm.editProfile = editProfile;
        vm.saveProfile = saveProfile;

        vm.$form = formBuilder.$new(
            User,
            [
                'email',
                'firstName',
                'lastName',
                'password',
                'passwordConfirm'
            ],
            { 'general': messageService.$get('profile_valid_error') }
        );

        vm.logout = logoutUser;

        vm.dashboard = goToDashboard;

        activate();

        function activate() {
            vm.user = vm.user.identity();
            if (vm.user.isLogged()) {
                vm.$form.setData(vm.user);
            }
        }

        function logoutUser() {
            var user = vm.user;
            vm.user = null;
            user.logout();
            $state.go('mco.home');
        }

        function editProfile() {
            session.$put(
                'profileReference',
                ngDialog.open(
                    {
                        className: 'ngdialog-theme-plain',
                        template: 'user/views/forms/profile.html',
                    }
                )
            );
        }

        function saveProfile(isValid) {
            if (isValid) {
                if (session.$isset('profileReference')) {
                    ngDialog.close(session.$get('profileReference'));
                }

                vm.user.profile(
                    vm.$form.getData(
                        [
                            'firstName',
                            'lastName',
                            'password',
                            'passwordConfirm'
                        ]
                    )
                ).then(
                    function(response) {
                        if (typeof response.data.token !== 'undefined' && response.data.token) {
                            vm.user.fromToken(response.data.token);
                            $window.sessionStorage.setItem('mco_jwt', response.data.token);
                            session.$put('user', vm.user);
                        }
                        return response;
                    }
                );
            } else {
                vm.$form.valid = false;
            }
        }

        function goToDashboard() {
            if (vm.user !== null && vm.user.isLogged()) {
                $state.go('mco.dashboard');
            } else {
                $state.go('mco.home');
            }
        }

    }
})(window.angular);
