(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.memorix').service('Comment', CommentProvider);


    /* @ngInject */
    function CommentProvider (resourceBuilder) {
        return resourceBuilder.$new(
            'comment',
            {id: '@_id'},
            {},
            {}
        );
    }
})(window.angular);
