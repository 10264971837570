(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.memorix')
        .directive('mmxForm', MmxForm);

    /* @ngInject */
    function MmxForm(urlHelper) {
        var directive = {
            restrict: 'E',
            replace: true,
            scope: {
                content: '=content'
            },
            templateUrl: 'memorix/views/directives/form.html',
            link: link
        };

        return directive;

        /**
         * @param {Object} scope
         * @param {DOMElement}
         * @param {Object} attributes
         **/
        function link(scope, element, attr) {
        }
    }
})(window.angular);
