(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.user').controller('User.LoginCtrl', LoginController);

    /* @ngInject */
    function LoginController ($state, $stateParams, $window, gettext, formBuilder, messageService, User) {
        var vm = this;
        var user = new User();
        vm.user = user.identity();
        
        vm.submit = login;
        vm.$form = formBuilder.$new(
            [
                'username',
                'password'
            ],
            messageService.$get('login_valid_error')
        );

        vm.placeHolders = {
            'username': gettext('E-mail'),
            'password': gettext('Password')
        };

        activate();

        function activate() {
            if (typeof $stateParams.status !== 'undefined') {
                messageService.$raise($stateParams.status, $stateParams.status);
                if ($stateParams.status === 'login_success') {
                    //This is the only place where we save the token to the
                    //session storage.
                    $window.sessionStorage.setItem('mco_jwt', $stateParams.token);
                    $state.transitionTo('mco.dashboard', {}, {reload: true, notify: true, inherit: true});
                } else {
                    $state.go('mco.home');
                }
            }
        }

        function login(isValid) {
            if (isValid) {
                vm.user.login(vm.$form.getData()).then(
                    function(response) {
                        if (typeof response.data.token !== 'undefined' && response.data.token) {
                            $window.sessionStorage.setItem('mco_jwt', response.data.token);
                            $state.transitionTo('mco.dashboard', {}, {reload:true, notify: true, inherit: true});
                        }
                    }
                );
            } else {
                vm.$form.valid = false;
            }
        }
    }
})(window.angular);
