(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.request').controller('Request.TabsCtrl', TabsController);

    /* @ngInject */
    function TabsController ($state, gettext, Request, Correction, Registration) {
        var vm = this;

        vm.list = [
            {active: false, label: gettext('Registrations'), target: 'mco.requests'},          
            {active: false, label: gettext('Institution access request'), target: 'mco.requests.extraregistrations', count: 0},
            {active: false, label: gettext('Institution corrections'), target: 'mco.requests.corrections', count: 0}
        ];

        angular.forEach(
            vm.list,
            function (tab) {
                if (tab.target === $state.current.name) {
                    tab.active = true;
                }
            }
        );

        vm.size = 'medium-';

        activate();

        function activate() {
            vm.size = vm.size + (12 / vm.list.length);
            Correction.query(
                {
                    'filter': {
                        'status': 'new'
                    }
                },
                function (response) {
                    vm.list[2].count = response.data.total;
                }
            );
            Request.query(
              {
                  'filter': {
                      'status': 'new'
                  }
              },
              function (response) {
                  vm.list[1].count = response.data.total;
              }
            );            
            Registration.query(
              {
                  'filter': {
                      'status': 'new'
                  }
              },
              function (response) {
                  vm.list[0].count = response.data.total;
              }
            );            
        }
    }
})(window.angular);
