(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.user').controller('User.FormCtrl', FormController);

    /* @ngInject */
    function FormController ($scope, $state, $window, gettext, urlHelper, messageService, 
        formBuilder, User, AutocompleteHelper, session, ngDialog) {
        var vm = this;

        vm.record = null;
        vm.resetPassword = userPassword;
        vm.removeRecord = removeRecord;
        vm.resetDone = false;

        vm.institutionsDisabled = false;
        vm.userInstitutionsDisabled = false;
        vm.denominationInstitutionsDisabled = false;
        vm.organizationInstitutions = false;

        vm.roles = {
            'Read only': gettext('Read only'),
            'Read only all': gettext('Read only all'),
            'Regular': gettext('Regular'),
            'Expert': gettext('Expert'),
            'Administrator': gettext('Administrator')
        };

        vm.$form = formBuilder.$new(
            User,
            [
                'firstName',
                'lastName',
                'institutions',
                'email',
                'role'
            ],
            {
                'general': messageService.$get('profile_valid_error')
            }
        );

        vm.institutionAutocomplete = AutocompleteHelper.$new(vm);
        vm.institutionAutocomplete['label'] = messageService.$get('autocomplete_inst_label');
        vm.institutionAutocomplete['url'] = urlHelper.getAutocompleteUrl('reginstitutions');

        vm.userAutocomplete = AutocompleteHelper.$new(vm);
        vm.userAutocomplete['label'] = messageService.$get('autocomplete_user_label', true);
        vm.userAutocomplete['url'] = urlHelper.getAutocompleteUrl('userinstitutions');

        vm.denominationAutocomplete = AutocompleteHelper.$new(vm);
        vm.denominationAutocomplete['label'] = messageService.$get('autocomplete_denomination_label', true);
        vm.denominationAutocomplete['url'] = urlHelper.getAutocompleteUrl('denominations');
        vm.denominationAutocomplete['chain'] = urlHelper.getAutocompleteUrl('denominationinstitutions');

        vm.organizationAutocomplete = AutocompleteHelper.$new(vm);
        vm.organizationAutocomplete['label'] = messageService.$get('autocomplete_organization_label', true);
        vm.organizationAutocomplete['url'] = urlHelper.getAutocompleteUrl('umbrellaorganizations');
        vm.organizationAutocomplete['chain'] = urlHelper.getAutocompleteUrl('umbrellaorganizationinstitutions');

        activate();

        function activate() {
            if ($scope.$parent.ngDialogData) {
                if (typeof $scope.$parent.ngDialogData.external !== 'undefined' &&
                    $scope.$parent.ngDialogData.external) {
                    messageService.$raise('user_edit_forbidden');
                } else {
                    vm.$form.setData($scope.$parent.ngDialogData);
                    vm.record = $scope.$parent.ngDialogData;
                }
            }
            return false;
        }

        function userPassword() {
            var user = new User(vm.record);
            user.rawRequest(
                'user/reset',
                'PUT',
                {},
                {'id': user._id}
            ).then(
                function (response) {
                    vm.resetDone = true;
                }
            );
        }

        function removeRecord() {
            /*jshint validthis:true */
            var confirm = $window.confirm('Weet u zeker dat u deze gebruiker wilt verwijderen?');
            if(confirm === true) {
              var user = new User(vm.record);
              if (session.$isset('dialogReference')) {
                ngDialog.close(session.$get('dialogReference'));
              }
  
              return user.rawRequest(
                  'user/' + user._id,
                  'DELETE',
                  {},
                  {}
              ).then(
                  function (response) {
                    $state.transitionTo('mco.users', {}, {reload:true, notify: true, inherit: true});
                  }
              );
            }
        }        
    }
})(window.angular);
