(function (angular, Dropzone) {
    'use strict';

    angular.module('McoCollectionPortal.memorix')
        .directive('mmxDropzone', MmxDropZone);

    /* @ngInject */
    function MmxDropZone() {

        var directive = {
            restrict: 'E',
            replace: true,
            scope: {
                config: '=config'
            },
            templateUrl: 'memorix/views/directives/drop-zone.html',
            link: link
        };

        return directive;

        function link(scope, element, attr) {
            var dropzoneEl = angular.element(element).find('.dropzone')[0];
            var dzone = null;

            dzone = new Dropzone(dropzoneEl, scope.config.options);
            angular.forEach(
                scope.config.eventHandlers,
                function (cb, event) {
                    dzone.on(event, cb);
                }
            );
        }
    }
})(window.angular, window.Dropzone);
