(function (angular, picturaeMedia, $, _) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.memorix').controller('Memorix.MediabankCtrl', MediabankController);

    /* @ngInject */
    function MediabankController ($state, gettext, urlHelper, spinner,
                                  User, CONFIG, messageService, gettextCatalog) {
        var vm = this;
        vm.monumentBank = null;
        vm.baseHref = CONFIG.mediabank.base;
        //Using angular & knockout was the dumbest idea ever.
        vm.comments = [];
        vm.commentIndex = 0;
        vm.commentType = 'story';
        vm.user = new User();
        vm.user = vm.user.identity();
        vm.tabs = [
            //{
            //    active: false,
            //    label: gettext('Comments'),
            //    count: 0,
            //    activate: function (tab) {
            //        tab.active = true;
            //    },
            //},
            {
                active: false,
                label: gettext('Stories'),
                count: 0,
                activate: function (tab) {
                    tab.active = true;
                },
            }
        ];
        vm.comments = [];
        vm.commentIndex = 0;
        vm.titlePlaceholder = gettext('Title');
        vm.textPlaceholder = gettext('Text');
        vm.institutionContext =  vm.user.getContext().acl;
        vm.size = 'medium-';
        vm.loadComments = loadComments;
        vm.exportType = 'Pdf';
        vm.monumentBankFq = [];
        activate();

        function activate() {
            $('#comments-section').hide();
            vm.size = vm.size + (12 / vm.tabs.length);

            picturaeMedia.require(
                ['Mediabank'],
                function (Mediabank) {
                    vm.monumentBank = new Mediabank(
                        {
                            'apiKey': 'private-api-key',
                            'filterRows': 39,
                            'entities': [
                                'd2d449bb-ff6d-a9cb-c962-528767d22902'
                            ],
                            'actions': {
                                'pdfExport': function () {
                                    vm.exportType = 'Pdf';
                                    if (vm.monumentBank.getViewModel().pagination.total() > 2000) {
                                        messageService.$raise('export_limit_size', 'export_limit_size');
                                    } else {
                                        $('#email-modal').modal();
                                        $('.export-mail').val(vm.user.email);
                                        //The default modal close uses a
                                        //hash state which breaks pager.js
                                        $('.close-modal').on(
                                            'click',
                                            function(e) {
                                                e.stopPropagation();
                                                $.modal.close();
                                                return false;
                                            }
                                        );
                                    }
                                },
                                'csvExport': function () {
                                    vm.exportType = 'Csv';
                                    $('#email-modal').modal();
                                    $('.export-mail').val(vm.user.email);
                                    $('.close-modal').on(
                                        'click',
                                        function(e) {
                                            e.stopPropagation();
                                            $.modal.close();
                                            return false;
                                        }
                                    );
                                }
                            },
                            'actionsLabels': {
                                'pdfExportLabel': gettextCatalog.getString(gettext('Export results as pdf.')),
                                'csvExportLabel': gettextCatalog.getString(gettext('Export results as csv.'))
                            },
                            'filters': [
                                {
                                    'field': 'search_s_valuation',
                                    'sort': 'index'
                                },
                                {
                                    'field': 'search_s_denomination',
                                    'sort': 'index'
                                },
                                {
                                    'field': 'search_s_sp_was_found',
                                    'sort': 'index'
                                },
                                {
                                    'field': 'search_s_institution_acl',
                                    'sort': 'index',
                                    'transform': 'lastonly'
                                }
                            ],
                            'pagination': {
                                'type': 'default'
                            },
                            'filtersCount': 5,
                            'toolTip': {
                                'appentPhotoName': true,
                                'settings': {
                                    'followMouse': true
                                }
                            },
                            'originalTitle': 'Objects',
                            'random': false,
                            'rows': 100,
                            'resultsPerPage' : [
                              20,
                              60,
                              100
                            ],
                            'searchOptions': {
                                'autocomplete': false,
                                'showPeriod': false
                            },
                            'server': urlHelper.getBaseUrl() + '/mediabank/object'
                        }
                    );


                    vm.monumentBank.run();

                    //I'm so sorry, but it is NOT possible to have the
                    //ko.js component function correctly in Angular.
                    //The angular $digest cycle breaks if one tries to
                    //use the framework after Pager.js has moved from
                    //the innitial page. Kill me now.
                    bindExport();
                    bindCorrections();
                    bindComments();
                    bindEvaluation();
                    bindEdit();

                    $(vm.monumentBank).on(
                        'onFilterAdd',
                        function (e, filter) {
                            vm.monumentBankFq = _.filter(
                                vm.monumentBankFq,
                                function (f) {
                                    return filter.field !== f.field;
                                }
                            );
                            vm.monumentBankFq.push(filter);
                        }
                    );

                    $(vm.monumentBank).on(
                        'onFilterRemove',
                        function (e, filter) {
                            vm.monumentBankFq = _.filter(
                                vm.monumentBankFq,
                                function (f) {
                                    return filter.field !== f.field;
                                }
                            );
                            vm.institutionContext = null;
                        }
                    );

                    $(vm.monumentBank).on(
                        'onAfterSetState',
                        function (e, view) {
                            if (vm.monumentBankFq.length > view.state.fq) {
                                $.each(
                                    vm.monumentBankFq,
                                    function (index, filter) {
                                        view.state.fq.push(
                                            filter.field + ':"' + filter.value.replace('"', '\"') + '"'
                                        );
                                    }
                                );
                            }
                        }
                    );

                    $(vm.monumentBank).on(
                        'onBeforeFormSubmit',
                        function (e, mb, pathDescriptor) {
                            var fqs = [];
                            $.each(
                                vm.monumentBankFq,
                                function (index, filter) {
                                    pathDescriptor.path += '&fq[' + filter.field + '][]=' + encodeURI(
                                        filter.value.replace('"', '\"')
                                    );
                                }
                            );
                        }
                    );

                    $(vm.monumentBank).on(
                        'reload',
                        function (e, mb, payload) {
                            payload.mcoUser = vm.user.getToken();
                            if (typeof $state.params.page === 'undefined') {
                                payload.mcoContext = vm.institutionContext;
                            } else {
                                payload.mcoContext = null;
                            }
                        }
                    );

                    $(vm.monumentBank).on(
                        'onAfterActiveAsset',
                        function() {
                            var record = vm.monumentBank.getViewModel().detail();
                            vm.commentType = 'story';
                            loadComments(record[0]);

                            //Check valuation & disable buttons
                            var isLocked = _.some(
                                record[0].metadata,
                                function (item) {
                                    return  item.field === 'valuation' && _.some(
                                        item.value,
                                        function (value) {
                                            return value === 'kerncollectie' ||
                                                value === 'collectie Nederland' ||
                                                value === 'lijst WBC' ||
                                                value === 'beschermde gegevens';
                                        }
                                    );
                                }
                            );

                            if ((isLocked&&
                                !vm.user.isAdmin() &&
                                !vm.user.isExpert())||
                                vm.user.isReadOnly() ||
                                vm.user.isReadOnlyAll()
                            ) {
                                $('.mediabank-report-object').prop('disabled', true);
                                $('.mediabank-evaluate-object').prop('disabled', true);
                                $('.mediabank-edit-object').prop('disabled', true);
                            } else {
                                $('.mediabank-report-object').prop('disabled', false);
                                $('.mediabank-evaluate-object').prop('disabled', false);
                                $('.mediabank-edit-object').prop('disabled', false);
                            }
                        }
                    );

                    $(vm.monumentBank).on(
                        'postReload',
                        function() {
                            var viewModel = vm.monumentBank.getViewModel();
                            if (viewModel.resultMode === 'detail') {
                                $('#comments-section').show();
                            } else if (viewModel.resultMode === 'gallery') {
                                $('#comments-section').hide();
                                //$('#comments-section').empty();
                                vm.comments = [];
                                vm.commentIndex = 0;
                            }
                        }
                    );
                }
            );
        }

        function loadComments(record, silent) {
            vm.comments = [];
            $('#comments-read').empty();
            spinner.start('#comments-section', vm);
            $.ajax({
                url: CONFIG.api.url + '/comment/' + record.id + '/' + vm.commentType,
                type: 'GET',
                crossDomain: true,
                headers: {
                    'Authorization':  'Bearer ' + vm.user.getToken()
                },
                success: function(response) {
                    spinner.stop('#comments-section', vm);
                    if (!_.isUndefined(response.comments) && response.comments.length > 0) {
                        vm.comments = response.comments;
                        $('#' + vm.commentType + '-count').text(vm.comments.length);
                        vm.commentIndex = 0;
                        showComment(0);
                    } else {
                        $('#' + vm.commentType + '-count').text('0');
                    }
                }
            });

            if (vm.commentType === 'comment') {
                $.ajax({
                    url: CONFIG.api.url + '/comment/' + record.id + '/story',
                    type: 'GET',
                    crossDomain: true,
                    headers: {
                        'Authorization':  'Bearer ' + vm.user.getToken()
                    },
                    success: function(response) {
                        if (!_.isUndefined(response.comments) && response.comments.length > 0) {
                            $('#story-count').text(response.comments.length);
                        }
                    }
                });
            }
        }

        function showComment(index) {
            $('#comments-read').empty();
            vm.commentIndex = index;
            var comment = vm.comments[vm.commentIndex];
            /*jshint multistr: true */
            var $commentTemplate = $('<div class="comment-content"> \
                                            <h4 class="title">' + comment.dc_subject + '</h4> \
                                            <p class="description">' + comment.dc_description +'</p> \
                                            <span class="author">' + comment.dc_creator + '</span> \
                                          </div> \
                                          <div class="comment-paginator"> \
                                            <span class="current">' + (index + 1) +'</span> / \
                                            <span class="total">'+ vm.comments.length+ '</span> \
                                            <button class="button-prev"><i class="icon-left"></i></button> \
                                            <button class="button-next"><i class="icon-right"></i></button> \
                                         </div>');

            $commentTemplate.find('.button-next').on(
                'click',
                function () {
                    if (vm.commentIndex + 1 < vm.comments.length) {
                        showComment(vm.commentIndex + 1);
                    }
                }
            );
            $commentTemplate.find('.button-prev').on(
                'click',
                function () {
                    if ((vm.commentIndex - 1)  >= 0) {
                        showComment((vm.commentIndex - 1));
                    }
                }
            );
            $('#comments-read').append($commentTemplate);
        }

        function bindComments() {
            $('.comment-tab').on(
                'click',
                function (e) {
                    e.stopPropagation();
                    vm.commentType = $(this).data('commentType');
                    $('.comment-tab').parent().removeClass('active');
                    $(this).parent().addClass('active');
                    loadComments(vm.monumentBank.getViewModel().detail()[0]);
                    return false;
                }
            );

            $('#comments-publish').on(
                'click',
                function (event) {
                    var commentTitle = $('#comments-title').val();
                    var commentBody = $('#comments-content').val();
                    var record = vm.monumentBank.getViewModel().detail()[0];

                    if (!_.isEmpty(record) && !_.isEmpty(commentTitle) && !_.isEmpty(commentBody)) {
                        $.ajax({
                            url: CONFIG.api.url + '/comment',
                            data: {
                                'record': record.id,
                                'title': commentTitle,
                                'body': commentBody,
                                'type': 'object',
                                'tags': vm.commentType
                            },
                            type: 'POST',
                            crossDomain: true,
                            headers: {
                                'Authorization':  'Bearer ' + vm.user.getToken()
                            },
                            success: function(response) {
                                if (!_.isUndefined(response.success) && response.success === 1) {
                                    messageService.$raise('comment_registered', 'comment_registered');
                                    $('#comments-title').val('');
                                    $('#comments-content').val('');
                                    loadComments(record);
                                } else {
                                    messageService.$raise('comment_register_error', 'comment_register_error');
                                }
                            }
                        });
                    }
                    return false;
                }
            );


        }

        function bindExport() {
            $('.start-export').on(
                'click',
                function () {
                    var email = $('.export-mail').val();
                    vm.monumentBank.getViewModel().exportResults(
                        vm.exportType,
                        email,
                        function (response) {
                            if (response.success === true) {
                                messageService.$raise('export_query', 'export_query');
                                $.modal.close();
                            } else {
                                messageService.$raise('export_query_failed', 'export_query_failed');
                            }
                        }
                    );
                }
            );
            $('.cancel-export').on(
                'click',
                function () {
                    $.modal.close();
                }
            );
        }

        function bindEvaluation() {
            $('.mediabank-evaluate-object').on(
                'click',
                function () {
                    var monumentObject = vm.monumentBank.getViewModel().detail()[0];
                    var monumentNumber = monumentObject['metadata'][0]['value'];
                    $state.transitionTo(
                        'mco.evaluation',
                        {'objectId': monumentObject.id, 'objectNumber': monumentNumber},
                        {reload:true, notify: true, inherit: true}
                    );
                }
            );
        }

        function bindEdit() {
            $('.mediabank-edit-object').on(
                'click',
                function () {
                    var monumentObject = vm.monumentBank.getViewModel().detail()[0];
                    var monumentNumber = _.filter(
                        monumentObject['metadata'],
                        function (f) {
                            return f['field'] === 'objectnumber';
                        }
                    );

                    if (monumentNumber.length === 1) {
                        monumentNumber = monumentNumber[0]['value'];
                    } else {
                        monumentNumber = '';
                    }

                    $state.transitionTo(
                        'mco.edit',
                        {
                            'objectId': monumentObject.id,
                            'objectNumber': monumentNumber,
                            'touchback': window.location.href
                        },
                        {reload:true, notify: true, inherit: true}
                    );
                }
            );
        }

        function bindCorrections() {
            $('.mediabank-report-object').on(
                'click',
                function () {
                    $('#report-modal').modal();
                    $('.close-modal').on(
                        'click',
                        function(e) {
                            e.stopPropagation();
                            $.modal.close();
                            return false;
                        }
                    );

                }
            );

            $('.cancel-report').on(
                'click',
                function () {
                    $.modal.close();
                }
            );

            $('.send-report').on(
                'click',
                function (event) {
                    var correctionSubject = $('.correction-subject').val();
                    var correctionBody = $('.correction-desc').val();
                    var record = vm.monumentBank.getViewModel().detail()[0];
                    if (!_.isEmpty(record) && !_.isEmpty(correctionSubject) && !_.isEmpty(correctionBody)) {
                        var correctionData = {
                            'institutionId' : vm.user.getContext().id,
                            'institutionDisplayName' : vm.user.getContext().title,
                            'recordId': record.id,
                            'recordDisplayName': !_.isEmpty(record.title) ? record.title : record.id,
                            'subject': correctionSubject,
                            'body': correctionBody,
                            'type': 'object'
                        };

                        // Add object number as record display name requested by refs. #25762
                        if (!_.isEmpty(record.metadata)) {
                            $.each(
                                record.metadata,
                                function (index, fieldData) {
                                    if (fieldData.field === 'objectnumber' && !_.isEmpty(fieldData.value)) {
                                        correctionData.recordDisplayName = fieldData.value;
                                    }
                                }
                            );
                        }

                        $.ajax({
                            url: CONFIG.api.url + '/correction',
                            data: correctionData,
                            type: 'POST',
                            crossDomain: true,
                            headers: {
                                'Authorization':  'Bearer ' + vm.user.getToken()
                            },
                            success: function(response) {
                                $.modal.close();
                                if (!_.isUndefined(response.status) && response.success === 'ok') {
                                    messageService.$raise(response.msg, response.msg);
                                    $('#comments-title').val('');
                                    $('#comments-content').val('');
                                } else {
                                    messageService.$raise(response.msg, response.msg);
                                }
                            }
                        });
                    }
                }
            );
        }
    }
})(window.angular, window.picturaeMediabank, window.$, window._);
