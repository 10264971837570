(function (angular) {
	'use strict';

	angular.module('McoCollectionPortal.core').filter('stringToDate', stringToDate);

	/* @ngInject */
	function stringToDate($filter) {
    return function (ele,dateFormat){

			if(ele === null) {
				return '';
			}
			// convert timestamp to date
			return $filter('date')(new Date(ele*1000),dateFormat);
		}
	}

})(window.angular);
