(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.core').service('urlHelper', UrlService);


    /* @ngInject */
    function UrlService (CONFIG) {
        var self = this;

        self.apiConfig = CONFIG;
        self.getBaseUrl = getBaseUrl;
        self.getAutocompleteUrl = getAutocompleteUrl;
        self.getFilesUrl = getFilesUrl;
        self.getInstitutionThumb = getInstitutionThumb;
        self.getInstitutionDownload = getInstitutionDownload;
        self.getMonumentRegisterUrl = getMonumentRegisterUrl;
        self.getMemorixInstitutionUrl = getMemorixInstitutionUrl;
        self.getMemorixObjectUrl = getMemorixObjectUrl;

        /**
         *  @return string
         **/
        function getBaseUrl() {
            return self.apiConfig.api.url;
        }

        /**
         * @param {String}
         * @return {String}
         **/
        function getAutocompleteUrl(entity, extra) {
            var url = getBaseUrl();
            if (typeof extra !== 'undefined') {
                url = url + '/autocomplete/' + entity + '?' + extra + 's=';
            } else {
                url = url + '/autocomplete/' + entity + '?s=';
            }
            return url;
        }

        /**
         * @return direct link.
         **/
        function getInstitutionThumb(mediaUuid) {
            var imageUrl = 'img/placeholder-photo.jpg';
            if (mediaUuid) {
                imageUrl = self.apiConfig.imageServer.url.replace('__UUID__', mediaUuid);
            }
            return imageUrl;
        }

        /**
         * @return direct link.
         **/
        function getInstitutionDownload(mediaUuid) {
            return self.apiConfig.imageServer.download.replace('__UUID__', mediaUuid);
        }

        /**
         * @return {String}
         **/
        function getMonumentRegisterUrl(constituentId) {
            return self.apiConfig.monumentRegister.url.replace('__CONSTITUENTID__', constituentId);
        }

        function getMemorixInstitutionUrl(institutionId) {
            return self.apiConfig.memorixInstitution.url.replace('__INSTITUTION__', institutionId);
        }

        function getMemorixObjectUrl(objectId) {
            return self.apiConfig.memorixObject.url.replace('__OBJECT__', objectId);
        }

        /**
         * @return {String}
         **/
        function getFilesUrl() {
            return getBaseUrl() + '/files';
        }
    }
})(window.angular);
