(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.request', [])
        .config( routeConfig );


    /* @ngInject */
    function routeConfig($stateProvider) {
        $stateProvider
            .state('mco.requests', {
                url: '/requests',
                views: {
                    '': {
                        templateUrl: 'request/views/index.html'
                    },
                    'listTabs@mco.requests': {
                      templateUrl: 'core/views/partials/tabs.html',
                      controller: 'Request.TabsCtrl',
                      controllerAs: 'tabs'                  
                    },
                    'requestGrid@mco.requests': {
                      templateUrl: 'registration/views/partials/grid.html',
                      controller: 'Registration.GridCtrl',
                      controllerAs: 'grid'                          

                    }
                },
                access: {
                    isAdmin: true
                }
            })
            .state('mco.requests.corrections', {
                url: '',
                views: {
                    'requestGrid@mco.requests': {
                        templateUrl: 'correction/views/partials/grid.html',
                        controller: 'Correction.GridCtrl',
                        controllerAs: 'grid'
                    }
                },
                access: {
                    isAdmin: true
                }
            })
            .state('mco.requests.extraregistrations', {
              url: '',
              views: {
                  'requestGrid@mco.requests': {
                    templateUrl: 'request/views/partials/grid.html',
                    controller: 'Request.GridCtrl',
                    controllerAs: 'grid'                    
                  }
              },
              access: {
                  isAdmin: true
              }
          });
    }
})(window.angular);
