(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.core').controller('Core.IndexCtrl', IndexController);

    /* @ngInject */
    function IndexController ($state, User) {
        var vm = this;
        vm.user = new User();

        activate();

        function activate() {
            vm.user = vm.user.identity();
            if (vm.user.isLogged()) {
                $state.go('mco.dashboard');
            }
        }
    }
})(window.angular);
