(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.core')
        .factory('jwt', JwtProvider);

    /* @ngInject */
    function JwtProvider(session, $window) {
        var jwt = {
            request: onHttpRequest,
            response: onHttpResponse
        };

        return jwt;

        function onHttpRequest(config) {
            if (session.$isset('user')) {
                config.headers.Authorization = 'Bearer ' + session.$get('user').getToken();
                config.withCredentials = true;
            } else {
                config.withCredentials = false;
            }
            return config;
        }

        function onHttpResponse(response) {
            if (typeof response.data.token !== 'undefined') {
                var user = session.$get('user');
                if (user) {
                    $window.sessionStorage.setItem('mco_jwt', response.data.token);
                    user.setToken(response.data.token);
                }
            }
            return response;
        }
    }
})(window.angular);
