(function (angular) {
    'use strict';

    // Register angular application & dependencies
    angular.module('McoCollectionPortal.correction').controller('Correction.FormCtrl', FormController);


    /* @ngInject */
    function FormController ($state, $scope, gettext, ngDialog, session, AutocompleteHelper,
                             formBuilder, Correction, User, messageService, urlHelper) {
        var vm = this;
        vm.record = {};
        vm.institutionUrl = null;
        vm.objectUrl = null;
        vm.user = new User();
        vm.user = vm.user.identity();
        vm.assignUser = assignUser;

        vm.$form = formBuilder.$new(
            Correction,
            [
                'subject',
                'reviewer',
                'body'
            ],
            {
                'general': messageService.$get('correction_valid_error')
            },
            {
                'prePersist': function (formData) {
                    formData['institutionId'] = vm.user.getContext().id;
                    formData['institutionDisplayName'] = vm.user.getContext().title;
                    formData['type'] = 'institution';
                    return formData;
                },
                'postPersist': function () {
                }
            }
        );

        vm.adminAutocomplete = AutocompleteHelper.$new(vm);
        vm.adminAutocomplete['url'] = urlHelper.getAutocompleteUrl('admins');        

        activate();

        function assignUser() {
            vm.record.reviewer = vm.$form.fields.reviewer;
            var correction = new Correction(vm.record);
            if (session.$isset('dialogReference')) {
                ngDialog.close(session.$get('dialogReference'));
            }

            return correction.rawRequest(
                'correction/assign',
                'PUT',
                {},
                {
                    'id': correction._id,
                    'reviewer': correction.reviewer
                }
            ).then(
                function (response) {
                    if (response.data.msg !== 'reg_assign_fail') {
                        $state.transitionTo('mco.requests.corrections', {}, {reload:true, notify: true, inherit: true});
                    }
                }
            );
        }

        function activate() {
            if ($scope.$parent.ngDialogData) {
                vm.$form.setData($scope.$parent.ngDialogData);
                vm.record = new Correction($scope.$parent.ngDialogData);
                vm.institutionUrl = urlHelper.getMemorixInstitutionUrl(vm.record.institutionId);
                if (vm.record.recordId) {
                    vm.objectUrl = urlHelper.getMemorixObjectUrl(vm.record.recordId);
                }
                vm.record.loadReferences();
            }
        }
    }
})(window.angular);
