(function (angular, _) {
    'use strict';

    //All this does is useless dispatch
    angular.module('McoCollectionPortal.dashboard').controller('Dashboard.ContextCtrl', ContextController);


    /* @ngInject */
    function ContextController ($rootScope, ngDialog, gettext, session, spinner, User,
                                Institution, messageService, urlHelper, AutocompleteHelper) {
        /* jshint validthis: true */
        var vm = this;
        var scope = $rootScope.$new();
        vm.nextThumb = nextThumb;
        vm.previousThumb = previousThumb;
        vm.currentThumb = null;
        vm.thumb = null;
        vm.thumbName = false;
        vm.urlHelper = urlHelper;
        vm.user = null;
        vm.institution = null;
        vm.textsOrder = {
            'Historisch overzicht' : 1,
            'Historisch overzicht  en biotoop' : 2,
            'Waardebepaling' : 3,
            'Waardebepaling en kerncollectie' : 4,
            'Conditie van de voorwerpen' : 5,
            'Conditie van de voorwerpen en aanbevelingen' : 6,
            'Conditie van de voorwerpen en klimatologische omstandigheden' : 7,
            'Literatuur' : 8,
            'Opmerkingen' : 9
        };
        vm.displayContextSwitch = displayContextSwitch;
        vm.displayContextPick = displayContextPick;
        vm.displayContext = displayContext;
        vm.linkInRegister = linkInRegister;
        vm.reportCorrection = reportCorrection;
        vm.requestAccess = requestAccess;
        vm.triggerContextSwitch = triggerContextSwitch;
        vm.readMore = readMore;
        vm.notEmpty = notEmpty;
        scope.selectInstitution = selectInstitution;
        scope.institutionAutocomplete = AutocompleteHelper.$new(vm);
        scope.institutionAutocomplete['label'] = gettext('Institution autocomplete...');
        scope.institutionAutocomplete['url'] = urlHelper.getAutocompleteUrl('reginstitutions');

        activate();

        function activate() {
            vm.user = new User();
            vm.user = vm.user.identity();
						vm.institution =vm.user.getContext();
            if (vm.user.getContext() === null) {
                vm.displayContext();
            } else {
                vm.nextThumb();
            }
        }

        function displayContext(withClose) {
            if (_.isUndefined(withClose)) {
                withClose = false;
            }

            scope.user = vm.user;
            if (vm.user.isAdmin() || vm.user.isReadOnlyAll()) {
                vm.displayContextPick(true);
            } else if (vm.user.institutionsCount > 0){
                vm.displayContextSwitch(withClose);
            }
        }

        function displayContextPick(withClose) {
            scope.selectedInstitution = scope.user.getContext();
            session.$put(
                'dialogReference',
                ngDialog.open(
                    {
                        className: 'ngdialog-theme-plain',
                        template: 'dashboard/views/partials/switch_admin.html',
                        scope: scope,
                        closeByEscape: false,
                        closeByDocument: false,
                        showClose: !_.isUndefined(withClose) && withClose
                    }
                )
            );
        }

        function displayContextSwitch(withClose) {
            if (_.isUndefined(vm.user.institutions)) {
                vm.user.institutions = vm.user.getInstitutions().then(
                    function(response) {
                        if (!_.isUndefined(response.data.list) && _.isArray(response.data.list)) {
                            vm.user.institutions = response.data.list;
                            vm.user.institutions = _.sortBy(
                                vm.user.institutions,
                                function (item) {
                                    return item.title;
                                }
                            );
                            scope.user.institutions = vm.user.institutions;
                        }
                        vm.triggerContextSwitch(withClose);
                    }
                );
            } else {
                vm.triggerContextSwitch(withClose);
            }
        }

        function triggerContextSwitch(withClose) {
            if (vm.user.institutions.length > 1) {
                if (scope.user.getContext() === null) {
                    scope.user.setContext(vm.user.institutions[0], true);
                } else {
                    //Deserialized objects from session storage are
                    //different, fix it.
                    scope.user.setContext(
                        _.find(
                            scope.user.institutions,
                            function (institution) {
                                return institution.id === scope.user.getContext().id;
                            }
                        ),
                        true
                    );
                }

                scope.selectedInstitution = scope.user.getContext();

                session.$put(
                    'dialogReference',
                    ngDialog.open(
                        {
                            className: 'ngdialog-theme-plain',
                            template: 'dashboard/views/partials/switch.html',
                            scope: scope,
                            closeByEscape: false,
                            closeByDocument: false,
                            showClose: !_.isUndefined(withClose) && withClose
                        }
                    )
                );
            } else {
                scope.selectInstitution(vm.user.institutions[0], true);
            }
        }

        function selectInstitution(record, silent) {
            var institutionResource = new Institution();
            if (record !== null && (!_.isUndefined(record.originalObject) || !_.isUndefined(record.id))) {
                if (!silent) {
                    spinner.start('.dashboard-institution', vm);
                }

                if (session.$isset('dialogReference')) {
                    ngDialog.close(session.$get('dialogReference'));
                }

                institutionResource.$get(
                    {id: _.isUndefined(record.originalObject) ? record.id : record.originalObject.id},
                    function (response) {
                        var contextInstitution = {};
                        var loadedInstitution = null;

                        if (typeof response.data.institution !== null &&
                            !_.isUndefined(response.data.institution.uuid)) {
                            loadedInstitution = response.data.institution;
                            contextInstitution = _.pick(
                                loadedInstitution,
                                [
                                    'media',
                                    'constituentid',
                                    'monument',
                                    'monument_number',
                                    'reliwiki',
                                    'name',
                                    'institution',
                                    'other_names',
                                    'denomination',
                                    'displaydate',
                                    'biography',
                                    'address',
                                    'zipcode',
                                    'city',
                                    'municipality',
                                    'province',
                                    'country',
                                    'commissioner',
                                    'remarks',
                                    'texts'
                                ]
                            );
                            if (_.isArray(contextInstitution['texts'])) {
                                contextInstitution['texts'] = _.sortBy(
                                    contextInstitution['texts'],
                                    function (t) {
                                        return !_.isUndefined(vm.textsOrder[t['texttype']]) ? vm.textsOrder[t['texttype']] : 0;  // jshint ignore:line
                                    }
                                );
                            }

                            contextInstitution['id'] = loadedInstitution.uuid;
                            contextInstitution['title'] = loadedInstitution.name;
                            contextInstitution['acl'] = loadedInstitution.constituentid
                              + '|'
                              + loadedInstitution.name.replace('+', '');
                            contextInstitution['pdf'] = null;
                            if (!_.isUndefined(contextInstitution['media']) &&
                                !_.isUndefined(contextInstitution['media'].length)) {
                                contextInstitution['pdf'] = _.find(
                                    contextInstitution.media,
                                    function (image) {
                                        return image.mimetype === 'application/pdf';
                                    }
                                );
                                contextInstitution['media'] = _.filter(
                                    contextInstitution.media,
                                    function (image) {
                                        return image.mimetype !== 'application/pdf';
                                    }
                                );
                            }
                            if (!_.isEmpty(contextInstitution['other_names']) &&
                                _.isArray(contextInstitution['other_names'])) {
                                contextInstitution['other_names'] = contextInstitution['other_names'].join(', ');
                            }

                            scope.user.setContext(contextInstitution);
                            vm.institution = scope.user.getContext();
                            vm.currentThumb = null;
                            vm.nextThumb();
                            if (!silent) {
                                spinner.stop('.dashboard-institution', vm);
                            }
                        }
                    }
                );
            }
        }

        function linkInRegister() {
            var institution = vm.user.getContext();
            var monumentNumber = '';
            if (institution && !_.isUndefined(institution['monument_number'])) {
                monumentNumber = institution['monument_number'];
            }
            return urlHelper.getMonumentRegisterUrl(monumentNumber);
        }

        function readMore(institution) {
            session.$put(
                'instReference',
                ngDialog.open(
                    {
                        className: 'ngdialog-theme-plain',
                        template: 'dashboard/views/partials/readmore.html',
                        data: JSON.stringify(institution)
                    }
                )
            );
        }

        function requestAccess() {
            session.$put(
                'dialogReference',
                ngDialog.open(
                    {
                        className: 'ngdialog-theme-plain',
                        template: 'request/views/forms/request.html'
                    }
                )
            );
        }

        function reportCorrection() {
            session.$put(
                'dialogReference',
                ngDialog.open(
                    {
                        className: 'ngdialog-theme-plain',
                        template: 'correction/views/forms/correction.html'
                    }
                )
            );
        }

        function notEmpty(container, value) {
            var empty = false;
            if (container) {
                if (typeof container[value] === 'undefined') {
                    empty = true;
                } else {
                    if (container[value] === null || container[value] === '') {
                        empty = true;
                    }
                    if (_.isArray(container[value]) && container[value].length === 0) {
                        empty = true;
                    }
                }
            }
            return !empty;
        }

        function nextThumb() {
            var media = vm.institution.media;
            var file = null;
            vm.thumbName = false;

            if (_.isNull(vm.currentThumb)) {
                vm.currentThumb = 0;
                if (!_.isUndefined(media) && !_.isUndefined(media.length)) {
                    _.each(
                        media,
                        function (m, index) {
                            if (m['default_thumb']) {
                                vm.currentThumb = index;
                            }
                        }
                    );
                    if (!_.isUndefined(media[vm.currentThumb])) {
                        if (!_.isUndefined(media[vm.currentThumb].caption) &&
                            !_.isEmpty(media[vm.currentThumb].caption)) {
                            vm.thumbName = media[vm.currentThumb].caption +
                                ' / foto ' +
                                media[vm.currentThumb].bestandsnaam;
                        } else {
                            vm.thumbName = 'instellingsfoto ' + media[vm.currentThumb].bestandsnaam;
                        }
                        file = media[vm.currentThumb].file;
                    }
                }
            } else if (!_.isUndefined(media) && !_.isUndefined(media.length)) {
                if (vm.currentThumb < (media.length - 1)) {
                    vm.currentThumb++;
                } else {
                    vm.currentThumb = 0;
                }
                if (!_.isUndefined(media[vm.currentThumb].caption) &&
                    !_.isEmpty(media[vm.currentThumb].caption)) {
                    vm.thumbName = media[vm.currentThumb].caption + ' / foto '  + media[vm.currentThumb].bestandsnaam;
                } else {
                    vm.thumbName = 'instellingsfoto ' + media[vm.currentThumb].bestandsnaam;
                }
                file = media[vm.currentThumb].file;
            }

            vm.thumb = urlHelper.getInstitutionThumb(file);
        }

        function previousThumb() {
            var media = vm.institution.media;
            var file = null;
            vm.thumbName = false;

            if (_.isNull(vm.currentThumb)) {
                vm.currentThumb = 0;
                if (!_.isUndefined(media) && !_.isUndefined(media.length)) {
                    if (!_.isUndefined(media[vm.currentThumb].caption) &&
                        !_.isEmpty(media[vm.currentThumb].caption)) {
                        vm.thumbName = media[vm.currentThumb].caption +
                            ' / foto ' +
                            media[vm.currentThumb].bestandsnaam;
                    } else {
                        vm.thumbName = 'instellingsfoto ' + media[vm.currentThumb].bestandsnaam;
                    }
                    file = media[vm.currentThumb].file;
                }
            } else if (!_.isUndefined(media) && !_.isUndefined(media.length)) {
                if (vm.currentThumb > 0) {
                    vm.currentThumb--;
                } else {
                    vm.currentThumb = media.length - 1;
                }
                if (!_.isUndefined(media[vm.currentThumb].caption) &&
                    !_.isEmpty(media[vm.currentThumb].caption)) {
                    vm.thumbName = media[vm.currentThumb].caption + ' / foto '  + media[vm.currentThumb].bestandsnaam;
                } else {
                    vm.thumbName = 'instellingsfoto ' + media[vm.currentThumb].bestandsnaam;
                }
                file = media[vm.currentThumb].file;
            }
            vm.thumb = urlHelper.getInstitutionThumb(file);
        }
    }
})(window.angular, window._);
