(function (angular, _) {
    'use strict';

    //All this does is useless dispatch
    angular.module('McoCollectionPortal.dashboard').controller('Dashboard.ReadMoreCtrl', ReadMoreController);

    /* @ngInject */
    function ReadMoreController ($sce, $scope, ngDialog, $state, gettext, User) {
        /* jshint validthis: true */
        var vm = this;
        vm.institution = null;

        activate();

        function activate() {
            if ($scope.$parent.ngDialogData) {
                vm.institution = $scope.$parent.ngDialogData;

                vm.institution['texts'] = _.map(
                    vm.institution['texts'],
                    function (t) {
                        if (!_.isNull(t['textentry'])) {
                            t['textentry'] = $sce
                                .trustAsHtml(
                                    t['textentry'].replace(/(?:\r\n|\r|\n)/g, '<br />')
                                );
                        }
                        
                        if (!_.isNull(t['remarks'])) {
                            t['remarks'] = $sce
                                .trustAsHtml(
                                    t['remarks'].replace(/(?:\r\n|\r|\n)/g, '<br />')
                                );
                        }
                        return t;
                    }
                );                

                if (!_.isEmpty(vm.institution['biography']) && 
                    !_.isNull(vm.institution['biography'])) {
                    vm.institution['biography'] = $sce
                        .trustAsHtml(
                            vm.institution['biography'].replace(/(?:\r\n|\r|\n)/g, '<br />')
                        );
                }
            }
        }
    }
})(window.angular, window._);
