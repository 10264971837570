(function (angular) {
    'use strict';

    angular.module('McoCollectionPortal.announcement').service('Announcement', Announcement);

    /* @ngInject */
    function Announcement ($window, session, ngDialog, resourceBuilder) {
        var persist = this;

        return resourceBuilder.$new(
            'announcement',
            {id: '@_id'},
            {},
            {}
        );
    }
})(window.angular);
