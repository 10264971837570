(function (angular, _) {
    'use strict';

    angular.module('McoCollectionPortal.memorix')
        .directive('mmxInline', MmxElement);

    /* @ngInject */
    function MmxElement() {
        var directive = {
            restrict: 'E',
            replace: true,
            scope: {
                content: '=content',
                owner: '=owner'
            },
            template: '<ng-include src="getElementTemplate()">',
            link: link
        };

        return directive;

        /**
         * @param {Object} scope
         * @param {DOMElement}
         * @param {Object} attributes
         **/
        function link(scope, element, attr) {
            scope.getElementTemplate = function () {
                if (typeof scope.content.inline === 'undefined') {
                    scope.content.inline = 'text';
                }
                var templatePath = 'memorix/views/inline/' +  scope.content.inline + '.html';
                return templatePath;
            };
        }
    }
})(window.angular, window._);
