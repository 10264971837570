(function (angular, $) {
    'use strict';

    angular.module('McoCollectionPortal.core')
        .factory('resourceBuilder', ResourceProvider);

    /* @ngInject */
    function ResourceProvider($q, $http, $resource, $state, CONFIG, messageService) {
        var resourceBuilder = {
            $new: createResource
        };

        return resourceBuilder;

        /**
         * Create a restful resource.
         * Example of answer to a request:
         ['status']
         * @param {String} Name of the resource.
         * @param {Object} Parameters for the ngResource
         * @param {Object} ngResource method definitions
         * @param {Object} Prototype behavioral extensions
         **/
        function createResource(resourceName, resourceParams, resourceMethods, prototypeExtensions) {
            var defaultMethods = {
                'get': {
                    method: 'GET',
                    isArray: false,
                    transformResponse: processGetResponse,
                    interceptor: {
                        response: processMsgResponse,
                        responseError: processErrorResponse
                    }
                },
                'query': {
                    method: 'GET',
                    isArray: false,
                    transformResponse: processQueryResponse,
                    interceptor: {
                        response: processMsgResponse,
                        responseError: processErrorResponse
                    }
                },
                'update': {
                    method: 'PUT',
                    interceptor: {
                        response: processMsgResponse,
                        responseError: processErrorResponse
                    }
                },
                'create': {
                    method: 'POST',
                    interceptor: {
                        response: processMsgResponse,
                        responseError: processErrorResponse
                    }
                },
                'remove': {
                    method: 'DELETE',
                    interceptor: {
                        response: processMsgResponse,
                        responseError: processErrorResponse
                    }
                },
                'delete':{
                    method: 'DELETE',
                    interceptor: {
                        response: processMsgResponse,
                        responseError: processErrorResponse
                    }
                }
            };

            var defaultPrototypeExtensions = {
                $save: saveResource,
                $ngTableFetch: gridResource,
                rawRequest: requestResource
            };

            resourceMethods = angular.extend( defaultMethods || {}, resourceMethods );

            prototypeExtensions = angular.extend( prototypeExtensions || {}, defaultPrototypeExtensions );


            var Resource = $resource(
                CONFIG.api.url + '/' + resourceName + '/:id',
                resourceParams,
                resourceMethods
            );

            //Attach behavior to the resource.
            angular.forEach(
                prototypeExtensions,
                function (f, fName) {
                    if (typeof f === 'function') {
                        Resource.prototype[fName] = f;
                    }
                }
            );

            return Resource;

            /**
             * Update or create a resource based on the presence of an ID.
             * @param {Object} Parameters for the resource call
             * @param {Function} success callback
             * @param {Function} error callback
             **/
            function saveResource($params, success, error) {
                /*jshint validthis:true */
                return !this._id ? this.$create($params, success, error) : this.$update($params, success, error);
            }

            /**
             * Use this function to integrate with an ngTable.
             * @param {Object} Parameters for the resource call
             * @param {Object} success callback
             **/
            function gridResource($deferred, params, grid) {
                /*jshint validthis:true */
                grid.loading = true;
                var sort = params.sorting();
                var filter = params.filter();
                var pagination = {
                    'limit': params.count(),
                    'skip': (params.page() - 1) * params.count()
                };
                this.$query(
                    {
                        'sort': sort,
                        'filter': filter,
                        'pagination': pagination
                    },
                    function(response) {
                        params.total(response.data.total);
                        $deferred.resolve(response.data.data);
                        grid.loading = false;
                    }
                );
            }

            /**
             * @param {String} Path
             * @param {String} Method - GET/POST/PUT/DELETE
             * @param {Object} Query parameters.
             * @param {Object} Request data.
             **/
            function requestResource(path, method, queryParams, data) {
                var requestOptions = {
                    method: method,
                    url: CONFIG.api.url + '/' +  path,
                    params: queryParams,
                    data: $.param(data || {})
                };
                if (method === 'POST' || method === 'PUT') {
                    requestOptions.headers = {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
                    };
                }
                return $http(requestOptions).then(
                    processMsgResponse
                );
            }

            /**
             * @param {Object} Response.
             * @param {Object} Response headers.
             **/
            function processQueryResponse(response, headers) {
                var unwrap = angular.fromJson(response);
                if (typeof unwrap.data === 'object') {
                    angular.forEach(
                        unwrap.data,
                        function(record, index) {
                            unwrap.data[index] = new Resource(record);
                        }
                    );
                }
                return unwrap;
            }

            /**
             * @param {Object} Response.
             * @param {Object} Response headers.
             **/
            function processGetResponse(response, headers) {
                var unwrap = angular.fromJson(response);
                if (typeof unwrap.data === 'object' && typeof unwrap.data['0'] !== 'undefined') {
                    unwrap.data = new Resource(unwrap.data['0']);
                }
                return unwrap;
            }

            /**
             * @param {Object} Response.
             **/
            function processMsgResponse(response) {
                if (typeof response.data.msg !== 'undefined' && response.data.msg !== 'http_success') {
                    messageService.$raise(response.data.msg, response.data.msg);
                }
                return response;
            }

            /**
             * @param {Object} Response.
             **/
            function processErrorResponse(response) {
                if (typeof response.data.msg !== 'undefined') {
                    if (response.data.code === 404 && response.data.msg === 'Unauthorized') {
                        $state.go('mco.logout');
                    } else {
                        messageService.$raise(response.data.msg, response.data.msg);
                    }
                }
                return $q.reject(response);
            }
        }
    }
})(window.angular, window.jQuery);
