(function (angular) {
    'use strict';

    //All this does is useless dispatch
    angular.module('McoCollectionPortal.dashboard').controller('Dashboard.AnnouncementsCtrl', AnnouncementsController);

    /* @ngInject */
    function AnnouncementsController ($state, messageService) {
        /* jshint validthis: true */
        var vm = this;

    }

})(window.angular);
